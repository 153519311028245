import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useToast } from '../../hooks/ToastContext';

import * as S from './styles';

import logo from '../../assets/images/logo_las.svg';
import api from '../../services/api';

const ForgotPassword: React.FC = () => {

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const [showForm, setShowForm] = useState<boolean>(true);
    const [showMessage, setShowMessage] = useState<boolean>(false);

    const { addToast } = useToast();

    console.log(email)

    function handleSubmit() {
        setLoading(true);
        api.post('reset-password', {
            email
        }).then(response => {

            if (response.status === 200) {
                setLoading(false);
                setShowForm(false);
                setShowMessage(true);
                console.log(response)
            }

        }).catch(error => {
            if (error.response) {
                setLoading(false);
                addToast({
                    type: 'error',
                    title: 'E-mail não encontrado'
                });
            }
        });
    }

    return (
        <S.Container>
            <S.Content>
                <S.Logo>
                    <img src={logo} alt="" />
                </S.Logo>
                {showForm && (
                    <>
                        <S.TitleWelcome>Esqueceu sua senha?</S.TitleWelcome>
                        <S.Form>
                            <S.TextInput
                                type="email"
                                placeholder="Digite seu e-mail"
                                onChange={e => setEmail(e.target.value)}
                            />
                            <S.ButtonSign
                                loading={loading}
                                onClick={handleSubmit}
                            >Recuperar minha senha</S.ButtonSign>
                        </S.Form>
                    </>
                )}

                {showMessage && (
                    <S.InfoEmail>
                        <S.IconSend />
                        As orientações para alteração de senha foram enviadas para o seu e-mail.
                    </S.InfoEmail>
                )}

                <S.LineHorizontal />

                <Link to="/">
                    <S.TextRegister>Voltar para tela inicial</S.TextRegister>
                </Link>
            </S.Content>
        </S.Container>
    );
}

export default ForgotPassword;