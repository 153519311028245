import React from 'react';
import { Link } from 'react-router-dom';

import * as S from './styles';

import { useAuth } from '../../hooks/AuthContext';

import logo from '../../assets/images/logo_las.svg';

const Header: React.FC = () => {

    const { signOut, user } = useAuth();

    return (
        <S.Container>
            <S.ContentHeader>
                <S.Logo>
                    <img src={logo} alt="" />
                </S.Logo>
                <S.Menu>
                    <nav>
                        {user.id_permission === 1 ? (
                            <>
                                <Link to="/personal-data">Meu currículo</Link>
                                <Link to="/opportunities">Oportunidades</Link>
                                <Link to="/candidates">Candidatos</Link>
                                <Link to="/register-admin">Admin</Link>
                                <Link to="" onClick={signOut} >Sair</Link>
                            </>
                        ) : (
                            <>
                                <Link to="/personal-data">Meu currículo</Link>
                                <Link to="/opportunities">Oportunidades</Link>
                                <Link to="" onClick={signOut} >Sair</Link>
                            </>
                        )}
                    </nav>
                </S.Menu>
            </S.ContentHeader>
        </S.Container >
    );
}

export default Header;