import styled from 'styled-components';

export const Container = styled.div`
    margin: 5px 0;
`;

export const InputField = styled.input`
    /* width: 400px; */
    height: 45px;
    background: #C4C4C4;
    border-radius: 5px;
    padding: 20px;
    margin: 3px 0;
    font-size: 12px;

    ::placeholder,
    ::-webkit-input-placeholder {
        color: #676767;
        /* padding: 10px; */
    }
    :-ms-input-placeholder {
        color: #676767;
        /* padding: 10px; */
    }
`;