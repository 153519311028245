import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import * as S from './styles';

import api from '../../services/api';

import { useAuth } from '../../hooks/AuthContext';
import { useToast } from '../../hooks/ToastContext';

import Header from '../../components/Header';
import Label from '../../components/Label';
import CardsHeader from '../../components/CardsHeader';

interface IPositions {
    id: string;
    code: string;
    name: string;
}

interface IExperiences {
    id: string;
    position: string;
    company_name: string;
    date_of_start: string;
    date_of_finished: string;
}

const ProfessionalTrajectory: React.FC = () => {

    const [loading, setLoading] = useState(false);
    const [positions, setPositions] = useState<IPositions[]>([]);
    const [positionNameAndCode, setPositionNameAndCode] = useState('');
    const [company, setCompany] = useState('');
    const [dateStart, setDateStart] = useState('');
    const [dateFinished, setDateFinished] = useState('');
    const [experiences, setExperiences] = useState<IExperiences[]>([]);
    const [loadingDelete, setLoadingDelete] = useState(false);

    const { user } = useAuth();
    const { addToast } = useToast();

    useEffect(() => {
        api.get('v1/get-positions').then(response => {
            setPositions(response.data);
        });
    }, []);

    useEffect(() => {
        api.get(`v1/professional-experience/${user.id}`).then(response => {
            setExperiences(response.data);
        });
    }, [user.id, loading, loadingDelete]);

    function formatDate(date: string) {
        const dateToFormat = date.split('/');

        const dateFormated = `${dateToFormat[2]}-${dateToFormat[1]}-${dateToFormat[0]}`;

        return dateFormated;
    }

    function handleSubmit() {
        setLoading(true);

        const positionArray = positionNameAndCode.split("-");

        api.post('v1/professional-experience', {
            id_user: user.id,
            position: positionArray[1],
            company_name: company,
            position_code: positionArray[0],
            date_of_start: formatDate(dateStart),
            date_of_finished: formatDate(dateFinished)
        }).then(response => {
            if (response.status === 201) {
                setLoading(false);
                setCompany('');
                setDateStart('');
                setDateFinished('');

                addToast({
                    type: 'success',
                    title: 'Histórico adicionado com sucesso!'
                });
            }
        });
    }

    function deleteExperience(id: string) {
        setLoadingDelete(true);
        api.delete(`v1/professional-experience/${id}`).then(response => {

            if (response.status === 204) {
                setLoadingDelete(false);

                addToast({
                    type: 'success',
                    title: 'Removido com sucesso!'
                });
            }

        });
    }

    return (
        <S.Container>
            <Header />
            <S.Content>

                <CardsHeader />

                <S.ContentTitle>
                    <S.Title>Experiência profissional</S.Title>
                    <S.LineHorizontal />
                </S.ContentTitle>

                <S.ContentForm>
                    <S.DescriptionForm>Vamos falar do seu histórico profissional. Compartilhe conosco as experiências mais relevantes da sua carreira.</S.DescriptionForm>
                    <S.RowForm>
                        <S.Col grid={3}>
                            <S.ContentInput>
                                <Label>Cargo:</Label>
                                <S.Select onChange={e => setPositionNameAndCode(e.target.value)}>
                                    <S.Option value={Number(0)} selected>Selecione</S.Option>
                                    {positions.map(position => (
                                        <S.Option key={position.id} value={`${position.code}-${position.name}`}>{position.name}</S.Option>
                                    ))}
                                </S.Select>
                            </S.ContentInput>
                        </S.Col>
                        <S.Col grid={3}>
                            <S.ContentInput>
                                <Label>Nome da empresa:</Label>
                                <S.InputText
                                    type="text"
                                    placeholder="Digite o nome da empresa"
                                    value={company}
                                    onChange={e => setCompany(e.target.value)}
                                />
                            </S.ContentInput>
                        </S.Col>

                        <S.Col grid={3}>
                            <S.ContentInput>
                                <Label>Data de entrada:</Label>
                                <S.InputTextMask
                                    mask="99/99/9999"
                                    type="text"
                                    placeholder="Digite a data de entrada"
                                    value={dateStart}
                                    onChange={e => setDateStart(e.target.value)}
                                />
                            </S.ContentInput>
                        </S.Col>

                        <S.Col grid={3}>
                            <S.ContentInput>
                                <Label>Data de saída:</Label>
                                <S.InputTextMask
                                    mask="99/99/9999"
                                    type="text"
                                    placeholder="Digite a data de saída"
                                    value={dateFinished}
                                    onChange={e => setDateFinished(e.target.value)}
                                />
                            </S.ContentInput>
                        </S.Col>
                    </S.RowForm>

                </S.ContentForm>

                <S.ContentButtons>
                    <S.ButtonNextOrSave
                        loading={loading}
                        onClick={handleSubmit}
                    >Adicionar</S.ButtonNextOrSave>
                </S.ContentButtons>

                <S.ContentTitle>
                    <S.Title>Experiência profissional cadastradas</S.Title>
                </S.ContentTitle>

                <S.ContentProfessionalExperience>
                    {experiences.map(experience => (
                        <S.RowColumns>
                            <S.Column>
                                <S.TextColumn>{experience.position}</S.TextColumn>
                            </S.Column>
                            <S.Column>
                                <S.TextColumn>{experience.company_name}</S.TextColumn>
                            </S.Column>
                            <S.Column>
                                <S.TextColumn>{experience.date_of_start}</S.TextColumn>
                            </S.Column>
                            <S.Column>
                                <S.TextColumn>{experience.date_of_finished}</S.TextColumn>
                            </S.Column>
                            <S.Column>
                                <S.ButtonDelete
                                    onClick={() => deleteExperience(experience.id)}
                                >-</S.ButtonDelete>
                            </S.Column>
                        </S.RowColumns>
                    ))}
                </S.ContentProfessionalExperience>

                <S.ContentButtons>
                    <Link to="/academic-formation">
                        <S.ButtonNextOrSave
                            loading={loading}
                            onClick={handleSubmit}
                        >Próximo</S.ButtonNextOrSave>
                    </Link>
                </S.ContentButtons>
            </S.Content>
        </S.Container>
    );
}

export default ProfessionalTrajectory;