import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import * as S from './styles';

import api from '../../services/api';

// import { useAuth } from '../../hooks/AuthContext';
import { useToast } from '../../hooks/ToastContext';

import Header from '../../components/Header';
import Label from '../../components/Label';
import { useEffect } from 'react';

interface IParamns {
	id: string;
}

const EditOpportunity: React.FC = () => {

	const [loading] = useState(false);
	const [locale, setLocale] = useState<string>();
	// const [city, setCity] = useState<string>();
	// const [state, setState] = useState<string>();
	const [description, setDescripton] = useState<string>();
	const [requirements, setRequirements] = useState<string>();
	const [benefits, setBenefits] = useState<string>();
	const [attributes, setAttributes] = useState<string>();
	const [status, setStatus] = useState<string>();

	const { addToast } = useToast();
	const history = useHistory();

	const { id } = useParams<IParamns>();

	useEffect(() => {
		api.get(`v1/oportunity/${id}`).then(response => {
			const { description, requirements, benefits, attributes, city, state, status } = response.data;

			setDescripton(description);
			setRequirements(requirements);
			setBenefits(benefits);
			setAttributes(attributes);
			setLocale(`${city} - ${state}`);
			setStatus(String(status));
		})
	}, [id]);

	function handleOpportunity() {

		const stateAndCity = locale?.split('-');

		api.put(`v1/oportunity/${id}`, {
			description,
			city: stateAndCity ? stateAndCity[0] : '',
			state: stateAndCity ? stateAndCity[1] : '',
			attributes,
			requirements,
			benefits,
			status: Number(status)
		}).then(response => {

			if (response.status === 200) {
				addToast({
					type: 'success',
					title: 'Vaga atualizada com sucesso'
				});
				history.push('/opportunities');
			}
		});
	}

	return (
		<S.Container>
			<Header />
			<S.Content>
				<S.ContentTitle>
					<S.Title>Atualização de oportunidade</S.Title>
					<S.LineHorizontal />
				</S.ContentTitle>

				<S.ContentForm>
					<S.RowForm>
						<S.Col grid={6}>
							<S.ContentInput>
								<Label>Vaga:</Label>
								<S.InputText
									type="text"
									placeholder="Digite o nome da vaga"
									value={description}
									onChange={e => setDescripton(e.target.value)}
								/>
							</S.ContentInput>
						</S.Col>
						<S.Col grid={3}>
							<S.ContentInput>
								<Label>Local da vaga:</Label>
								<S.Select onChange={e => setLocale(e.target.value)}>
									<S.Option value={locale} selected>{locale}</S.Option>
									<S.Option value="Goiânia - GO">Goiânia - GO</S.Option>
									<S.Option value="Anápolis - GO">Anápolis - GO</S.Option>
								</S.Select>
							</S.ContentInput>
						</S.Col>

						<S.Col grid={3}>
							<S.ContentInput>
								<Label>Status da vaga:</Label>
								<S.Select onChange={e => setStatus(e.target.value)}>
									<S.Option value={status} selected>{status === '1' ? 'Disponível' : 'Indisponível'}</S.Option>
									<S.Option value="1">Disponível</S.Option>
									<S.Option value="0">Indisponível</S.Option>
								</S.Select>
							</S.ContentInput>
						</S.Col>
					</S.RowForm>

					<S.RowForm>
						<S.Col grid={12}>
							<S.ContentInput>
								<Label>Descrição:</Label>
								<S.TextArea
									placeholder="Digite a descrição da vaga"
									value={attributes}
									onChange={e => setAttributes(e.target.value)}
								/>
							</S.ContentInput>
						</S.Col>
					</S.RowForm>

					<S.RowForm>
						<S.Col grid={12}>
							<S.ContentInput>
								<Label>Requisitos:</Label>
								<S.TextArea
									placeholder="Digite os requisitos da vaga"
									value={requirements}
									onChange={e => setRequirements(e.target.value)}
								/>
							</S.ContentInput>
						</S.Col>
					</S.RowForm>

					<S.RowForm>
						<S.Col grid={12}>
							<S.ContentInput>
								<Label>Benefícios:</Label>
								<S.TextArea
									placeholder="Digite os benefícios da vaga"
									value={benefits}
									onChange={e => setBenefits(e.target.value)}
								/>
							</S.ContentInput>
						</S.Col>
					</S.RowForm>
				</S.ContentForm>

				<S.ContentButtons>
					<S.ButtonNextOrSave
						loading={loading}
						onClick={handleOpportunity}
					>Atualizar</S.ButtonNextOrSave>
				</S.ContentButtons>
			</S.Content>
		</S.Container>
	);
}

export default EditOpportunity;