import React, { useState, useEffect } from 'react';

import * as S from './styles';

import api from '../../services/api';

import Header from '../../components/Header';
import Label from '../../components/Label';

interface IDataUsers {
    name: string;
    cpf: string;
    email: string;
    phone: string;
}

const Candidates: React.FC = () => {

    const [dataUsers, setDataUsers] = useState<IDataUsers[]>([]);
    const [dateStart, setDateStart] = useState<string>('');
    const [dateEnd, setDateEnd] = useState<string>('');
    const [search, setSearch] = useState<string>('');

    const url_export = 'https://las.app.br/curriculo_api/public/api/v1/';

    function formatDate(date: string) {

        const dateArray = date.split('-');
        const dateFormated = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;

        console.log(dateFormated);

        return dateFormated;
    }

    function getDataUsers() {
        api.get(`v1/admin-list-users?search=${search}&date_ini=${dateStart}&date_end=${dateEnd}`).then(response => {
            setDataUsers(response.data.data);
        });
    }

    function openTabExport(type: string) {
        window.open(`${url_export}export?date_ini=${dateStart}&date_end=${dateEnd}&search=${search}&format=${type}`);
    }

    useEffect(() => {
        getDataUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <S.Container>
            <Header />
            <S.Content>
                <S.ContentFilters>
                    <S.ContentTitle>
                        <S.Title>Filtros</S.Title>
                        <S.LineHorizontal />
                    </S.ContentTitle>
                    <S.RowForm>

                        <S.Col grid={2}>
                            <S.ContentInput>
                                <Label>Data inicial:</Label>
                                <S.InputTextMask
                                    mask="99-99-9999"
                                    type="text"
                                    placeholder="Digite a data inicial"
                                    onChange={e => setDateStart(formatDate(e.target.value))}
                                />
                            </S.ContentInput>
                        </S.Col>

                        <S.Col grid={2}>
                            <S.ContentInput>
                                <Label>Data final:</Label>
                                <S.InputTextMask
                                    mask="99-99-9999"
                                    type="text"
                                    placeholder="Digite a data final"
                                    onChange={e => setDateEnd(formatDate(e.target.value))}
                                />
                            </S.ContentInput>
                        </S.Col>

                        <S.Col grid={4}>
                            <S.ContentInput>
                                <Label>Pesquisar:</Label>
                                <S.InputText
                                    type="text"
                                    placeholder="Pesquisar por: nome, e-mail, cpf"
                                    onChange={e => setSearch(e.target.value)}
                                />
                            </S.ContentInput>
                        </S.Col>

                        <S.Col grid={4}>
                            <S.ContentButtons>
                                <S.ButtonSearch
                                    onClick={() => getDataUsers()}
                                >
                                    <S.IconSearch />
                                    Buscar
                                </S.ButtonSearch>

                                <S.ButtonExport onClick={() => openTabExport('csv')}>
                                    <S.IconDownload />
                                    .CSV
                                </S.ButtonExport>

                                <S.ButtonExport onClick={() => openTabExport('')}>
                                    <S.IconDownload />
                                    .XLS
                                </S.ButtonExport>
                            </S.ContentButtons>
                        </S.Col>
                    </S.RowForm>
                </S.ContentFilters>

                <S.RowForm>
                    <S.Col grid={12}>
                        <S.ContentTitle>
                            <S.Title>Listagem de candidatos</S.Title>
                            <S.LineHorizontal />
                        </S.ContentTitle>
                        <S.ContentTableHeader>
                            <S.ColumnName>
                                <h1>Nome</h1>
                            </S.ColumnName>
                            <S.ColumnCpf>
                                <h1>CPF</h1>
                            </S.ColumnCpf>
                            <S.ColumnEmail>
                                <h1>E-mail</h1>
                            </S.ColumnEmail>
                            <S.Phone>
                                <h1>Telefone</h1>
                            </S.Phone>
                        </S.ContentTableHeader>

                        {dataUsers.map(user => (
                            <S.ContentTable>
                                <S.ColumnName>
                                    <h1>{user.name}</h1>
                                </S.ColumnName>
                                <S.ColumnCpf>
                                    <h1>{user.cpf}</h1>
                                </S.ColumnCpf>
                                <S.ColumnEmail>
                                    <h1>{user.email}</h1>
                                </S.ColumnEmail>
                                <S.Phone>
                                    <h1>{user.phone}</h1>
                                </S.Phone>
                            </S.ContentTable>
                        ))}

                    </S.Col>
                </S.RowForm>
            </S.Content>
        </S.Container>
    );
};

export default Candidates;


