import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import api from '../../services/api';
import { useToast } from '../../hooks/ToastContext';

import * as S from './styles';

const SignUp: React.FC = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [cpf, setCpf] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const { addToast } = useToast();
    const history = useHistory();

    function handleSubmit() {
        if (name === '' || email === '' || password === '' || confirmPassword === '') {
            addToast({
                type: 'error',
                title: 'Todos os campos são obrigatórios',
            });
        } else {
            if (password !== confirmPassword) {
                addToast({
                    type: 'error',
                    title: 'As senhas não conferem',
                });
            } else {
                setLoading(true)

                api.post('v1/register', {
                    name,
                    email,
                    password,
                    cpf
                }).then(response => {
                    if (response.status === 201) {

                        setLoading(false);

                        history.push('/');

                        addToast({
                            type: 'success',
                            title: 'Usuário cadastrado com sucesso'
                        });
                    }
                }).catch(error => {
                    setLoading(false);
                    // addToast({
                    //     type: 'error',
                    //     title: 'E-mail ou senha em branco',
                    //     description: 'Todos os campos são obrigatórios'
                    // });
                });
            }
        }
    }

    return (
        <S.Container>
            <S.Content>
                <S.TitleWelcome>Cadastro do Portal do Candidato</S.TitleWelcome>
                <S.Form>
                    <S.TextInput
                        type="text"
                        placeholder="Digite seu nome"
                        onChange={e => setName(e.target.value)}
                    />
                    <S.TextInput
                        type="email"
                        placeholder="Digite seu e-mail"
                        onChange={e => setEmail(e.target.value)}
                    />
                    <S.InputTextMask
                        mask="999.999.999-99"
                        type="text"
                        placeholder="Digite seu cpf"
                        onChange={e => setCpf(e.target.value)}
                    />
                    <S.TextInput
                        type="password"
                        placeholder="Digite sua senha"
                        onChange={e => setPassword(e.target.value)}
                    />
                    <S.TextInput
                        type="password"
                        placeholder="Confirme sua senha"
                        onChange={e => setConfirmPassword(e.target.value)}
                    />

                    <S.ButtonSign
                        loading={loading}
                        onClick={handleSubmit}
                    >Cadastre-se</S.ButtonSign>

                    <Link to="/">
                        <S.TextForgotPassword>Já tenho conta</S.TextForgotPassword>
                    </Link>
                </S.Form>
            </S.Content>
        </S.Container>
    );
}

export default SignUp;