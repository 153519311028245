import styled from 'styled-components';

export const Container = styled.div`
    /* position: absolute; */
    position: fixed;
    right: 0;
    top: 0;
    padding: 30px;
    overflow: hidden;
`;

