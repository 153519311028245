import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useToast } from '../../hooks/ToastContext';
import { useAuth } from '../../hooks/AuthContext';

import * as S from './styles';

import logo from '../../assets/images/logo_las.svg';

const SignIn: React.FC = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const { addToast } = useToast();
    const { signIn } = useAuth();

    async function handleSubmit() {
        try {
            if (email === '' || password === '') {
                addToast({
                    type: 'error',
                    title: 'Todos os campos são obrigatórios'
                });
            } else {
                setLoading(true);

                await signIn({
                    email,
                    password
                });

                setLoading(false);

                addToast({
                    type: 'success',
                    title: 'Seja bem-vindo(a)!',
                });
            }
        } catch (error) {
            setLoading(false);
            if (error.response.data.error) {
                addToast({
                    type: 'success',
                    title: 'Seja bem-vindo(a)!',
                });
            }
        }
    }

    return (
        <S.Container>
            <S.Content>
                <S.Logo>
                    <img src={logo} alt="" />
                </S.Logo>
                <S.TitleWelcome>Acesso ao Portal do Candidato</S.TitleWelcome>
                <S.Form>
                    <S.TextInput
                        type="email"
                        placeholder="Digite seu e-mail"
                        onChange={e => setEmail(e.target.value)}
                    />
                    <S.TextInput
                        type="password"
                        placeholder="Digite sua senha"
                        onChange={e => setPassword(e.target.value)}
                    />

                    <S.ButtonSign
                        loading={loading}
                        onClick={handleSubmit}
                    >Acessar</S.ButtonSign>
                    <Link to="/forgot-password">
                        <S.TextForgotPassword>Esqueceu a senha?</S.TextForgotPassword>
                    </Link>
                </S.Form>
                <S.LineHorizontal />

                <S.TextRegister>Ainda não tem cadastro?</S.TextRegister>

                <Link to="/signup">
                    <S.ButtonSign>Cadastre-se</S.ButtonSign>
                </Link>
            </S.Content>
        </S.Container>
    );
}

export default SignIn;