import styled from 'styled-components';

export const Container = styled.div`

`;

export const ContentHeader = styled.div`
    height: 85px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Logo = styled.div`
    margin-top: 10px;
`;

export const Menu = styled.div`
    display: flex;
    nav {
        a {
            color: #737380;
            font-size: 15.5px;
            padding: 0 15px;
        }
    }
`;