import React, { useEffect, useState } from 'react';

import * as S from './styles';

import api from '../../services/api';

import { useAuth } from '../../hooks/AuthContext';
import { useToast } from '../../hooks/ToastContext';

import Header from '../../components/Header';
import Label from '../../components/Label';
import CardsHeader from '../../components/CardsHeader';

interface ICourses {
    id: string;
    level: string;
    course: string;
    institution: string;
    situation: string;
    date_of_start: string;
    date_of_finished: string;
}

interface ICourseKnowLedge {
    code: string;
    name: string;
}

interface IKnowledges {
    id: string;
    code: string;
    date_of_start: string;
    date_of_finished: string;
    level_of_reading: string;
    level_of_writing: string;
    level_of_conversation: string;
    course_name: string;
}

const AcademicFormation: React.FC = () => {

    const [loading, setLoading] = useState(false);
    const [level, setLevel] = useState('');
    const [course, setCourse] = useState('');
    // const [courseCode, setCourseCode] = useState('');
    const [institution, setInstitution] = useState('');
    const [situation, setSituation] = useState('');
    const [dateStart, setDateStart] = useState('');
    const [dateFinished, setDateFinished] = useState('');
    const [courses, setCourses] = useState<ICourses[]>([]);
    const [coursesKnowledge, setCoursesKnowledge] = useState<ICourseKnowLedge[]>([]);
    const [code, setCode] = useState('');
    const [dateStartKnowledge, setDateStartKnowledge] = useState('');
    const [dateFinishedKnowledge, setDateFinishedKnowledge] = useState('');
    const [levelReading, setLevelReading] = useState('');
    const [levelWriting, setLevelWriting] = useState('');
    const [levelConversation, setLevelConversation] = useState('');
    const [knowledges, setKnowledges] = useState<IKnowledges[]>([]);
    const [loadingDelete, setLoadingDelete] = useState(false);

    const { user } = useAuth();
    const { addToast } = useToast();

    useEffect(() => {
        api.get(`v1/academic-information/${user.id}`).then(response => {
            setCourses(response.data);
        });
    }, [user.id, loading, loadingDelete]);

    useEffect(() => {
        api.get(`v1/knowledge/${user.id}`).then(response => {
            setKnowledges(response.data);
        });
    }, [user.id, loading, loadingDelete]);

    useEffect(() => {
        api.get('v1/get-courses').then(response => {
            setCoursesKnowledge(response.data);
        });
    }, []);

    function formatDate(date: string) {
        const dateToFormat = date.split('/');

        const dateFormated = `${dateToFormat[2]}-${dateToFormat[1]}-${dateToFormat[0]}`;

        return dateFormated;
    }

    function handleSubmitFormationAcademic() {
        setLoading(true);

        api.post('v1/academic-information', {

            id_user: user.id,
            level,
            course,
            course_code: "66565",
            institution,
            situation,
            date_of_start: formatDate(dateStart),
            date_of_finished: formatDate(dateFinished)

        }).then(response => {

            setCourse('');
            setInstitution('');
            setDateStart('');
            setDateFinished('');

            if (response.status === 201) {
                setLoading(false);

                addToast({
                    type: 'success',
                    title: 'Curso adicionado com sucesso!'
                });
            }
        });
    }

    function deleteKnowledge(id: string) {
        setLoadingDelete(true);
        api.delete(`v1/knowledge/${id}`).then(response => {

            if (response.status === 204) {
                setLoadingDelete(false);

                addToast({
                    type: 'success',
                    title: 'Removido com sucesso!'
                });
            }

        });
    }

    function deleteCourse(id: string) {
        setLoadingDelete(true);
        api.delete(`v1/academic-information/${id}`).then(response => {

            if (response.status === 204) {
                setLoadingDelete(false);

                addToast({
                    type: 'success',
                    title: 'Removido com sucesso!'
                });
            }

        });
    }

    function handleSubmitKnowledge() {
        setLoading(true);
        setDateStartKnowledge('');
        setDateFinishedKnowledge('');

        api.post('v1/knowledge', {
            id_user: user.id,
            code,
            date_of_start: formatDate(dateStartKnowledge),
            date_of_finished: formatDate(dateFinishedKnowledge),
            level_of_reading: levelReading,
            level_of_writing: levelWriting,
            level_of_conversation: levelConversation
        }).then(response => {
            if (response.status === 201) {
                setLoading(false);

                addToast({
                    type: 'success',
                    title: 'Histórico adicionado com sucesso!'
                });
            }
        });
    }

    return (
        <S.Container>
            <Header />
            <S.Content>

                <CardsHeader />

                <S.ContentTitle>
                    <S.Title>Formação Acadêmica</S.Title>
                    <S.LineHorizontal />
                </S.ContentTitle>

                <S.ContentForm>
                    <S.DescriptionForm>Informe-nos os cursos de formação que mais contribuíram para a sua carreira</S.DescriptionForm>
                    <S.RowForm>
                        <S.Col grid={3}>
                            <S.ContentInput>
                                <Label>Nível de formação:</Label>
                                <S.Select
                                    onChange={e => setLevel(e.target.value)}
                                >
                                    <S.Option value={Number(0)} disabled selected>Selecione</S.Option>
                                    <S.Option value="1">Ensino Fundamental - Normal</S.Option>
                                    <S.Option value="2">Ensino Fundamental - Supletivo</S.Option>
                                    <S.Option value="3">Ensino Médio - Normal</S.Option>
                                    <S.Option value="4">Ensino Médio - Supletivo</S.Option>
                                    <S.Option value="5">Ensino Médio - Técnico</S.Option>
                                    <S.Option value="6">Superior</S.Option>
                                    <S.Option value="7">Pós-Graduação - Especialização</S.Option>
                                    <S.Option value="8">Pós-Graduação - Mestrado</S.Option>
                                    <S.Option value="9">Pós-Graduação - Doutorado</S.Option>
                                    <S.Option value="10">Pós-Doutorado</S.Option>
                                    <S.Option value="11">Livre Docência</S.Option>
                                    <S.Option value="12">Adjunto</S.Option>
                                    <S.Option value="13">Titular</S.Option>
                                    <S.Option value="14">MBA</S.Option>
                                </S.Select>
                            </S.ContentInput>
                        </S.Col>

                        <S.Col grid={3}>
                            <S.ContentInput>
                                <Label>Curso:</Label>
                                <S.InputText
                                    type="text"
                                    placeholder="Digite o nome da empresa"
                                    value={course}
                                    onChange={e => setCourse(e.target.value)}
                                />
                            </S.ContentInput>
                        </S.Col>

                        <S.Col grid={3}>
                            <S.ContentInput>
                                <Label>Instituição de ensino:</Label>
                                <S.InputText
                                    type="text"
                                    placeholder="Digite o nome da empresa"
                                    value={institution}
                                    onChange={e => setInstitution(e.target.value)}
                                />
                            </S.ContentInput>
                        </S.Col>

                        <S.Col grid={3}>
                            <S.ContentInput>
                                <Label>Situação:</Label>
                                <S.Select
                                    onChange={e => setSituation(e.target.value)}
                                >
                                    <S.Option value={Number(0)} disabled selected>Selecione</S.Option>
                                    <S.Option value="1">Andamento</S.Option>
                                    <S.Option value="2">Completo</S.Option>
                                    <S.Option value="3">Desistente</S.Option>
                                    <S.Option value="4">Suspenso Temporariamente</S.Option>
                                    <S.Option value="5">Reprovado Média</S.Option>
                                    <S.Option value="6">Reprovado Frequência</S.Option>
                                    <S.Option value="8">Transferido</S.Option>
                                </S.Select>
                            </S.ContentInput>
                        </S.Col>
                    </S.RowForm>

                    <S.RowForm>
                        <S.RowForm>
                            <S.Col grid={3}>
                                <S.ContentInput>
                                    <Label>Data de início:</Label>
                                    <S.InputTextMask
                                        mask="99/99/9999"
                                        type="text"
                                        placeholder="Digite a data de entrada"
                                        value={dateStart}
                                        onChange={e => setDateStart(e.target.value)}
                                    />
                                </S.ContentInput>
                            </S.Col>

                            <S.Col grid={3}>
                                <S.ContentInput>
                                    <Label>Data de conclusão:</Label>
                                    <S.InputTextMask
                                        mask="99/99/9999"
                                        type="text"
                                        placeholder="Digite a data de saída"
                                        value={dateFinished}
                                        onChange={e => setDateFinished(e.target.value)}
                                    />
                                </S.ContentInput>
                            </S.Col>
                        </S.RowForm>
                    </S.RowForm>
                </S.ContentForm>

                <S.ContentButtons>
                    <S.ButtonNextOrSave
                        loading={loading}
                        onClick={handleSubmitFormationAcademic}
                    >Adicionar</S.ButtonNextOrSave>
                </S.ContentButtons>

                <S.ContentTitle>
                    <S.Title>Cursos cadastrados</S.Title>
                </S.ContentTitle>

                <S.ContentProfessionalExperience>
                    {courses.map(course => (
                        <S.RowColumns>
                            <S.Column>
                                <S.TextColumn>{course.level}</S.TextColumn>
                            </S.Column>
                            <S.Column>
                                <S.TextColumn>{course.course}</S.TextColumn>
                            </S.Column>
                            <S.Column>
                                <S.TextColumn>{course.institution}</S.TextColumn>
                            </S.Column>
                            <S.Column>
                                <S.TextColumn>{course.situation}</S.TextColumn>
                            </S.Column>
                            <S.Column>
                                <S.TextColumn>{course.date_of_start}</S.TextColumn>
                            </S.Column>
                            <S.Column>
                                <S.TextColumn>{course.date_of_finished}</S.TextColumn>
                            </S.Column>
                            <S.Column>
                                <S.ButtonDelete
                                    onClick={() => deleteCourse(course.id)}
                                >-</S.ButtonDelete>
                            </S.Column>
                        </S.RowColumns>
                    ))}
                </S.ContentProfessionalExperience>

                <S.ContentTitle>
                    <S.Title>Idiomas / Conhecimentos</S.Title>
                    <S.LineHorizontal />
                </S.ContentTitle>

                <S.ContentForm>
                    <S.RowForm>
                        <S.Col grid={3}>
                            <S.ContentInput>
                                <Label>Curso:</Label>
                                <S.Select
                                    onChange={e => setCode(e.target.value)}
                                >
                                    <S.Option value={Number(0)} disabled selected>Selecione</S.Option>
                                    {coursesKnowledge.map(courseKnowledge => (
                                        <S.Option value={courseKnowledge.code}>{courseKnowledge.name}</S.Option>
                                    ))}
                                </S.Select>
                            </S.ContentInput>
                        </S.Col>

                        <S.Col grid={3}>
                            <S.ContentInput>
                                <Label>Nível de leitura:</Label>
                                <S.Select
                                    onChange={e => setLevelReading(e.target.value)}
                                >
                                    <S.Option value={Number(0)} disabled selected>Selecione</S.Option>
                                    <S.Option value="N">Não conhece</S.Option>
                                    <S.Option value="R">Razoável</S.Option>
                                    <S.Option value="B">Bem</S.Option>
                                    <S.Option value="F">Fluente</S.Option>
                                </S.Select>
                            </S.ContentInput>
                        </S.Col>

                        <S.Col grid={3}>
                            <S.ContentInput>
                                <Label>Nível de escrita:</Label>
                                <S.Select
                                    onChange={e => setLevelWriting(e.target.value)}
                                >
                                    <S.Option value={Number(0)} disabled selected>Selecione</S.Option>
                                    <S.Option value="N">Não conhece</S.Option>
                                    <S.Option value="R">Razoável</S.Option>
                                    <S.Option value="B">Bem</S.Option>
                                    <S.Option value="F">Fluente</S.Option>
                                </S.Select>
                            </S.ContentInput>
                        </S.Col>

                        <S.Col grid={3}>
                            <S.ContentInput>
                                <Label>Nível de conversação:</Label>
                                <S.Select
                                    onChange={e => setLevelConversation(e.target.value)}
                                >
                                    <S.Option value={Number(0)} disabled selected>Selecione</S.Option>
                                    <S.Option value="N">Não conhece</S.Option>
                                    <S.Option value="R">Razoável</S.Option>
                                    <S.Option value="B">Bem</S.Option>
                                    <S.Option value="F">Fluente</S.Option>
                                </S.Select>
                            </S.ContentInput>
                        </S.Col>
                    </S.RowForm>

                    <S.RowForm>
                        <S.Col grid={3}>
                            <S.ContentInput>
                                <Label>Data de início:</Label>
                                <S.InputTextMask
                                    mask="99/99/9999"
                                    type="text"
                                    placeholder="Digite a data de entrada"
                                    value={dateStartKnowledge}
                                    onChange={e => setDateStartKnowledge(e.target.value)}
                                />
                            </S.ContentInput>
                        </S.Col>

                        <S.Col grid={3}>
                            <S.ContentInput>
                                <Label>Data de conclusão:</Label>
                                <S.InputTextMask
                                    mask="99/99/9999"
                                    type="text"
                                    placeholder="Digite a data de saída"
                                    value={dateFinishedKnowledge}
                                    onChange={e => setDateFinishedKnowledge(e.target.value)}
                                />
                            </S.ContentInput>
                        </S.Col>
                    </S.RowForm>
                </S.ContentForm>

                <S.ContentButtons>
                    <S.ButtonNextOrSave
                        loading={loading}
                        onClick={handleSubmitKnowledge}
                    >Adicionar</S.ButtonNextOrSave>
                </S.ContentButtons>

                <S.ContentTitle>
                    <S.Title>Conhecimentos cadastrados</S.Title>
                </S.ContentTitle>

                <S.ContentProfessionalExperience>
                    {knowledges.map(knowledge => (
                        <S.RowColumns>
                            <S.Column>
                                <S.TextColumn>{knowledge.course_name}</S.TextColumn>
                            </S.Column>
                            <S.Column>
                                <S.TextColumn>{knowledge.level_of_reading}</S.TextColumn>
                            </S.Column>
                            <S.Column>
                                <S.TextColumn>{knowledge.level_of_writing}</S.TextColumn>
                            </S.Column>
                            <S.Column>
                                <S.TextColumn>{knowledge.level_of_conversation}</S.TextColumn>
                            </S.Column>
                            <S.Column>
                                <S.TextColumn>{knowledge.date_of_start}</S.TextColumn>
                            </S.Column>
                            <S.Column>
                                <S.TextColumn>{knowledge.date_of_finished}</S.TextColumn>
                            </S.Column>
                            <S.Column>
                                <S.ButtonDelete
                                    onClick={() => deleteKnowledge(knowledge.id)}
                                >-</S.ButtonDelete>
                            </S.Column>
                        </S.RowColumns>
                    ))}
                </S.ContentProfessionalExperience>

                <S.ContentButtons>
                    <S.TextLinkSaveAndContinue>Salvar e continuar depois</S.TextLinkSaveAndContinue>
                    <S.ButtonNextOrSave>Finalizar</S.ButtonNextOrSave>
                </S.ContentButtons>
            </S.Content>
        </S.Container>
    );
}

export default AcademicFormation;