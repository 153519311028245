import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 15px;
`;

export const ButtonField = styled.button`
    background: #2B3B85;
    height: 45px;
    border-radius: 5px;
    border-width: 0;
    cursor: pointer;
    font-size: 14px;
    color: #FFF;
    font-weight: 600;
    border: none;
    transition: background-color 0.2s;

    &:hover {
        background: #172877;
    }
`;