import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import * as S from './styles';

import api from '../../services/api';

import { useToast } from '../../hooks/ToastContext';

import Header from '../../components/Header';
import Label from '../../components/Label';

interface IParamns {
	id: string;
}

const EditAdmin: React.FC = () => {

	const [name, setName] = useState<string>();
	const [email, setEmail] = useState<string>();
	const [cpf, setCpf] = useState<string>();
	const [phone, setPhone] = useState<string>();
	const [password, setPassword] = useState<string>();
	const [loading, setLoading] = useState<boolean>(false);

	const { addToast } = useToast();

	const { id } = useParams<IParamns>();

	useEffect(() => {
		api.get(`v1/user/${id}`).then(response => {
			const { name, email, cpf, phone } = response.data;

			setName(name);
			setEmail(email);
			setCpf(cpf);
			setPhone(phone);
		});
	}, [loading, id]);

	function handleEditAdmin() {

		setLoading(true);

		api.put(`v1/user/${id}`, {
			name,
			password,
			id_permission: 1,
			email,
			phone,
			cpf
		}).then(response => {

			if (response.status === 200) {
				addToast({
					type: 'success',
					title: 'Admin atualizado com sucesso'
				});
			}
		})
	}

	return (
		<S.Container>
			<Header />
			<S.Content>
				<S.ContentFilters>
					<S.ContentTitle>
						<S.Title>Editar administrador</S.Title>
						<S.LineHorizontal />
					</S.ContentTitle>
					<S.RowForm>

						<S.Col grid={4}>
							<S.ContentInput>
								<Label>Nome:</Label>
								<S.InputText
									type="text"
									placeholder="Digite o nome completo"
									value={name}
									onChange={e => setName(e.target.value)}
								/>
							</S.ContentInput>
						</S.Col>

						<S.Col grid={4}>
							<S.ContentInput>
								<Label>E-mail:</Label>
								<S.InputText
									type="text"
									placeholder="Digite o e-mail"
									value={email}
									onChange={e => setEmail(e.target.value)}
								/>
							</S.ContentInput>
						</S.Col>

						<S.Col grid={4}>
							<S.ContentInput>
								<Label>CPF:</Label>
								<S.InputTextMask
									mask="999.999.999-99"
									type="text"
									value={cpf}
									placeholder="Digite o cpf"
									onChange={e => setCpf(e.target.value)}
									disabled
								/>
							</S.ContentInput>
						</S.Col>
					</S.RowForm>

					<S.RowForm>
						<S.Col grid={4}>
							<S.ContentInput>
								<Label>Telefone:</Label>
								<S.InputTextMask
									mask="(99) 99999-9999"
									type="text"
									placeholder="Digite o telefone"
									value={phone}
									onChange={e => setPhone(e.target.value)}
								/>
							</S.ContentInput>
						</S.Col>

						<S.Col grid={3}>
							<S.ContentInput>
								<Label>Senha:</Label>
								<S.InputText
									type="password"
									placeholder="Digite a senha"
									value={password}
									onChange={e => setPassword(e.target.value)}
								/>
							</S.ContentInput>
						</S.Col>

						<S.Col grid={3}>
							<S.ButtonRegister
								onClick={() => handleEditAdmin()}
								loading={loading}
							>Atualizar</S.ButtonRegister>
						</S.Col>
					</S.RowForm>
				</S.ContentFilters>
			</S.Content>
		</S.Container>
	);
};

export default EditAdmin;


