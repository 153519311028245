import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { useToast } from '../../hooks/ToastContext';

import * as S from './styles';

import logo from '../../assets/images/logo_las.svg';
import api from '../../services/api';

interface IParamsProps {
    token: string;
    email: string;
}

const ResetPassword: React.FC = () => {

    const [password, setPassword] = useState<string>();
    const [confirmPassword, setConfirmPassword] = useState<string>();
    const [loading, setLoading] = useState(false);

    const { addToast } = useToast();
    
    const history = useHistory();

    const { token, email } = useParams<IParamsProps>();

    function handleSubmit() {

        setLoading(true);

        if (password !== confirmPassword || password === '' || confirmPassword === '') {
            
            setLoading(false);

            addToast({
                type: 'error',
                title: 'As senhas não conferem'
            });
        } else {
            api.post('change-password', {
                email,
                passwordToken: token,
                password,
                password_confirmation: confirmPassword
            }).then(response => {
                if (response.status === 201) {

                    setLoading(false);

                    addToast({
                        type: 'success',
                        title: 'Senha alterada com sucesso!',
                    });

                    history.push("/");
                }
            }).catch(error => {

                setLoading(false);

                if (error.response) {
                    addToast({
                        type: 'error',
                        title: 'Não foi possível alterar a senha, tente novamente mais tarde'
                    });
                }
            });
        }
    }

    return (
        <S.Container>
            <S.Content>
                <S.Logo>
                    <img src={logo} alt="" />
                </S.Logo>
                <S.TitleWelcome>Alterar senha</S.TitleWelcome>
                <S.Form>
                    <S.TextInput
                        type="password"
                        placeholder="Digite sua nova senha"
                        onChange={e => setPassword(e.target.value)}
                    />
                    <S.TextInput
                        type="password"
                        placeholder="Confirme sua nova senha"
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                    <S.ButtonSign
                        loading={loading}
                        onClick={handleSubmit}
                    >Salvar</S.ButtonSign>
                </S.Form>
            </S.Content>
        </S.Container>
    );
}

export default ResetPassword;