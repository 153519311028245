import React, { ButtonHTMLAttributes } from 'react';

import Loader from "react-loader-spinner";

import * as S from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    loading?: boolean;
};

const Button: React.FC<ButtonProps> = ({ children, loading, ...rest }) => {
    return (
        <S.Container>
            <S.ButtonField type="button" {...rest}>
                {loading ? (
                    <Loader
                        type="ThreeDots"
                        color="#ffffff"
                        height={35}
                        width={35}
                        timeout={0} //3 secs
                    />
                ) : children}
            </S.ButtonField>
        </S.Container>
    );
}

export default Button;