import React from 'react';
import { Link } from 'react-router-dom';

import iconPersonalData from '../../assets/images/icon_personal_data.svg';
import iconProfessionalTrajectory from '../../assets/images/icon_professional_trajectory.svg';
import iconAcademicEducation from '../../assets/images/icon_academic_education.svg';

import * as S from './styles';

const CardsHeader: React.FC = () => {
    return (
        <S.ContentHeader>

            <S.Card bg="#32C5D2">
                <Link to="/personal-data">
                    <S.CardImage>
                        <S.IconCard src={iconPersonalData} />
                    </S.CardImage>
                    <S.CardBottom bg="#2398A3">
                        <S.TextCard>Dados Pessoais</S.TextCard>
                    </S.CardBottom>
                </Link>
            </S.Card>

            <S.Card bg="#289306">
                <Link to="/professional-trajectory">
                    <S.CardImage>
                        <S.IconCard src={iconProfessionalTrajectory} />
                    </S.CardImage>
                    <S.CardBottom bg="#207406">
                        <S.TextCard>Trajetória Profissional</S.TextCard>
                    </S.CardBottom>
                </Link>
            </S.Card>

            <S.Card bg="#5C1751">
                <Link to="/academic-formation">
                    <S.CardImage>
                        <S.IconCard src={iconAcademicEducation} />
                    </S.CardImage>
                    <S.CardBottom bg="#3E0A35">
                        <S.TextCard>Formação Acadêmica</S.TextCard>
                    </S.CardBottom>
                </Link>
            </S.Card>
            {/* 
            <S.Card bg="#5C3A05">
                <S.CardImage>
                    <S.IconCard src={iconSkills} />
                </S.CardImage>
                <S.CardBottom bg="#392B13">
                    <S.TextCard>Conhecimentos e Habilidades</S.TextCard>
                </S.CardBottom>
            </S.Card> */}
        </S.ContentHeader>
    );
}

export default CardsHeader;