import React, {InputHTMLAttributes} from 'react';

import * as S from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    placeholder: string;
    type: string;
  }

const Input: React.FC<InputProps> = ( {...rest}) => {
    return(
        <S.Container>
            <S.InputField  { ...rest} />
        </S.Container>
    );
}

export default Input;