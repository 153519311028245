import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import * as S from './styles';

import api from '../../services/api';

import Header from '../../components/Header';

import { useAuth } from '../../hooks/AuthContext';
import { useToast } from '../../hooks/ToastContext';

interface ICandidates {
    name: string;
    email: string;
    phone: string;
    cpf: string;
}

interface IOpportunity {
    id: string;
    cod: string;
    description: string;
    city: string;
    state: string;
    attributes: string;
    requirements: string;
    benefits: string;
}

interface IParams {
    id: string;
}

const Opportunity: React.FC = () => {

    const [opportunity, setOpportunity] = useState<IOpportunity>({} as IOpportunity);
    const [candidates, setCandidates] = useState<ICandidates[]>([]);
    const [loading, setLoading] = useState(false);

    const { id } = useParams<IParams>();

    const { user } = useAuth();
    const { addToast } = useToast();

    useEffect(() => {
        api.get(`v1/oportunity/${id}`).then(response => {
            setOpportunity(response.data);
            setCandidates(response.data.users);
        });
    }, [id]);

    function handleApply() {
        setLoading(true);

        api.post('v1/apply', {
            user_id: user.id,
            oportunity_id: id
        }).then(response => {
            if (response.status === 200) {
                setLoading(false);
                addToast({
                    type: 'success',
                    title: 'Candidatura efetuada com sucesso'
                });
            }
        });
    }

    return (
        <S.Container>
            <Header />
            <S.Content>
                <S.ContentTitle>
                    <S.Title>{`Vaga: ${opportunity.description} - Código: ${opportunity.cod}`}</S.Title>
                    <S.ButtonApply
                        loading={loading}
                        onClick={handleApply}
                    >Candidatar</S.ButtonApply>
                </S.ContentTitle>
                <S.LineHorizontal />

                <S.ContentForm>
                    <S.TitleOpportunity>Descrição:</S.TitleOpportunity>
                    <S.DescriptionForm>{opportunity.attributes}</S.DescriptionForm>

                    <S.TitleOpportunity>Requisitos:</S.TitleOpportunity>
                    <S.DescriptionForm>{opportunity.requirements}</S.DescriptionForm>

                    <S.TitleOpportunity>Benefícios:</S.TitleOpportunity>
                    <S.DescriptionForm>{opportunity.benefits}</S.DescriptionForm>

                    <S.TitleOpportunity>Local:</S.TitleOpportunity>
                    <S.DescriptionForm>{`${opportunity.city} - ${opportunity.state}`}</S.DescriptionForm>
                </S.ContentForm>

            </S.Content>
            {user.id_permission === 1 && (
                <S.ContentCandidates>
                    <S.ContentTitle>
                        <S.Title>Candidatos</S.Title>
                    </S.ContentTitle>
                    <S.LineHorizontal />

                    <S.RowForm>
                        <S.Col grid={12}>

                            <S.ContentTableHeader>
                                <S.ColumnName>
                                    <h1>Nome</h1>
                                </S.ColumnName>
                                <S.ColumnCpf>
                                    <h1>CPF</h1>
                                </S.ColumnCpf>
                                <S.ColumnEmail>
                                    <h1>E-mail</h1>
                                </S.ColumnEmail>
                                <S.Phone>
                                    <h1>Telefone</h1>
                                </S.Phone>
                            </S.ContentTableHeader>

                            {candidates.map(user => (
                                <S.ContentTable>
                                    <S.ColumnName>
                                        <h1>{user.name}</h1>
                                    </S.ColumnName>
                                    <S.ColumnCpf>
                                        <h1>{user.cpf}</h1>
                                    </S.ColumnCpf>
                                    <S.ColumnEmail>
                                        <h1>{user.email}</h1>
                                    </S.ColumnEmail>
                                    <S.Phone>
                                        <h1>{user.phone}</h1>
                                    </S.Phone>

                                </S.ContentTable>
                            ))}

                        </S.Col>
                    </S.RowForm>
                </S.ContentCandidates>
            )}

        </S.Container>
    );
}

export default Opportunity;