import React, { useEffect, useState, useCallback, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';

import * as S from './styles';

import api from '../../services/api';

import { useAuth } from '../../hooks/AuthContext';
import { useToast } from '../../hooks/ToastContext';

import Header from '../../components/Header';
import Label from '../../components/Label';
import CardsHeader from '../../components/CardsHeader';

interface IAddressStateData {
    id: string;
    name: string;
    code: string;
}

interface IAddressNeightborhoodData {
    id: string;
    city_code: string;
    name: string;
}

interface IPositions {
    id: string;
    code: string;
    name: string;
}

const PersonalData: React.FC = () => {

    const [loading] = useState(false);
    const [image, setImage] = useState<File>();
    const [previewImage, setPreviewImage] = useState<string>();
    const [name, setName] = useState('');
    const [gender, setGender] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [cpf, setCpf] = useState('');
    const [maritalStatus, setMaritalStatus] = useState('');
    const [numberOfChildren, setNumberOfChildren] = useState('');
    const [rgNumber, setRgNumber] = useState('');
    const [rgEmittingOrgan, setRgEmittingOrgan] = useState('');
    const [rgCity, setRgCity] = useState('');
    const [rgState, setRgState] = useState('');
    const [rgNationality, setRgNationality] = useState('');
    const [birth, setBirth] = useState('');
    const [fatherName, setFatherName] = useState('');
    const [motherName, setMotherName] = useState('');
    const [pisNumber, setPisNumber] = useState('');
    const [deficiency, setDeficiency] = useState('');
    const [knowSomeone, setKnowSomeone] = useState('');
    const [selectiveProcess, setSelectiveProcess] = useState('');
    const [typeAddress, setTypeAddress] = useState('');
    const [address, setAddress] = useState('');
    const [addressComplement, setAddressComplement] = useState('');
    const [addressNumber, setAddressNumber] = useState('');
    const [addressNeighborhood, setAddressNeightborhood] = useState('');
    const [addressCity, setAddressCity] = useState('');
    const [addressState, setAddressState] = useState('');
    const [addressPostCode, setAddressPostCode] = useState('');
    const [addressStateData, setAddressStateData] = useState<IAddressStateData[]>([]);
    const [addressCitySelected, setAddressCitySelected] = useState('');
    const [addressNeightborhoodData, setAddressNeightborhoodData] = useState<IAddressNeightborhoodData[]>([]);
    const [positions, setPositions] = useState<IPositions[]>([]);
    const [intendedPosition, setIntendedPosition] = useState('');
    const [intendedArea, setIntendedArea] = useState('');
    const [wageClaim, setWageClaim] = useState('');
    const [loadingPhoto, setLoadingPhoto] = useState(false);

    const { user } = useAuth();
    const { addToast } = useToast();
    const history = useHistory();

    const getDataUser = useCallback(async () => {
        const response = await api.get(`v1/user/${user.id}`);

        const { name, gender, email, phone, cpf, image, civil_state, number_of_children, rg_number, rg_emitting_organ, rg_city, rg_state, rg_nationality, birth, father_name, mother_name, pis_number, deficiency, know_someone_who_works_at_the_company, selective_process, type_of_address, address, address_complement, address_number, address_neighborhood, address_city, address_state, address_postcode, intended_area, intended_position, wage_claim } = response.data;

        if (birth) {
            const dateArray = birth.split("-");

            const dateInputFormated = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;

            setBirth(dateInputFormated);
        }

        setName(name);
        setGender(gender);
        setEmail(email);
        setPhone(phone);
        setCpf(cpf);
        setPreviewImage(image);
        setMaritalStatus(civil_state);
        setNumberOfChildren(number_of_children);
        setRgNumber(rg_number);
        setRgEmittingOrgan(rg_emitting_organ);
        setRgCity(rg_city);
        setRgState(rg_state);
        setRgNationality(rg_nationality);
        setFatherName(father_name);
        setMotherName(mother_name);
        setPisNumber(pis_number);
        setDeficiency(deficiency);
        setKnowSomeone(know_someone_who_works_at_the_company);
        setSelectiveProcess(selective_process);
        setTypeAddress(type_of_address);
        setAddress(address);
        setAddressComplement(address_complement);
        setAddressNumber(address_number);
        setAddressNeightborhood(address_neighborhood);
        setAddressCity(address_city);
        setAddressState(address_state);
        setAddressPostCode(address_postcode);
        setIntendedArea(intended_area);
        setIntendedPosition(intended_position);
        setWageClaim(wage_claim);

    }, [user.id]);

    useEffect(() => {
        getDataUser();
    }, [getDataUser, loadingPhoto]);

    useEffect(() => {
        if (addressState !== '') {
            api.get(`v1/get-cities/${addressState}`).then(response => {
                setAddressStateData(response.data);
            });
        }
    }, [addressState]);

    useEffect(() => {
        if (addressCitySelected !== '') {
            api.get(`v1/get-neighbordoods/${addressCitySelected}`).then(response => {
                setAddressNeightborhoodData(response.data);
            });
        }
    }, [addressCitySelected]);

    useEffect(() => {
        api.get('v1/get-positions').then(response => {
            setPositions(response.data);
        });
    }, []);

    useEffect(() => {
        api.get(`v1/get-neighbordoods/5208707`).then(response => {
            setAddressNeightborhoodData(response.data);
        });
    }, []);

    useEffect(() => {
        if (image) {
            handleSaveUserPhoto();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingPhoto, image]);

    function formatDate(date: string) {
        const dateToFormat = date.split('-');
        const dateFormated = `${dateToFormat[2]}-${dateToFormat[1]}-${dateToFormat[0]}`;
        
        return dateFormated;
    }

    function handleSaveUser() {

        api.put(`v1/user/${user.id}`, {
            name,
            gender,
            email,
            phone,
            cpf,
            civil_state: maritalStatus,
            number_of_children: numberOfChildren,
            rg_number: rgNumber,
            rg_emitting_organ: rgEmittingOrgan,
            rg_city: rgCity,
            rg_state: rgState,
            rg_nationality: rgNationality,
            birth: formatDate(birth),
            father_name: fatherName,
            mother_name: motherName,
            pis_number: pisNumber,
            deficiency,
            know_someone_who_works_at_the_company: knowSomeone,
            selective_process: selectiveProcess,
            type_of_address: typeAddress,
            address,
            address_complement: addressComplement,
            address_number: addressNumber,
            address_neighborhood: addressNeighborhood,
            address_city: addressCitySelected,
            address_state: addressState,
            address_postcode: addressPostCode,
            intended_area: intendedArea,
            intended_position: intendedPosition,
            wage_claim: wageClaim
        }).then(response => {
            if (response.status === 200) {
                addToast({
                    type: 'success',
                    title: 'Alterações salva com sucesso'
                });
            }
        });
    }

    function handleSaveAndNextPage() {
        handleSaveUser();

        history.push('/professional-trajectory');
    }

    function handleSelectImage(event: ChangeEvent<HTMLInputElement>) {

        if (!event.target.files) return;

        setImage(event.target.files[0]);
        setLoadingPhoto(true);
    }

    function handleSaveUserPhoto() {

        const data = new FormData();

        data.append('id_user', user.id);
        data.append('image', Object(image), image?.name);

        api.post('v1/upload-user-photo', data,).then(response => {
            setLoadingPhoto(false);
        });
    }

    return (
        <S.Container>
            <Header />
            <S.Content>
                <CardsHeader />

                <S.ContentTitle>
                    <S.Title>Dados Pessoais</S.Title>
                    <S.LineHorizontal />
                </S.ContentTitle>

                <S.ContentForm>
                    <S.RowForm>
                        <S.Col grid={2}>
                            <S.ContentPhoto>
                                {previewImage ? (
                                    <S.Image src={`https://las.app.br/curriculo_api/storage/app/public/users/${previewImage}`} />
                                ) : (
                                    <S.Image />
                                )}

                                <S.ButtonUpLoadPhoto
                                    htmlFor="image"
                                >Envia Foto</S.ButtonUpLoadPhoto>

                                <S.InputFile
                                    type="file"
                                    accept="image/*"
                                    id="image"
                                    onChange={handleSelectImage}
                                />
                            </S.ContentPhoto>
                        </S.Col>
                        <S.Col grid={10}>
                            <S.RowForm>
                                <S.Col grid={12}>
                                    <S.ContentInput>
                                        <Label>Nome completo:</Label>
                                        <S.InputText
                                            type="text"
                                            placeholder="Digite seu nome completo"
                                            value={name}
                                            onChange={e => setName(e.target.value)}
                                        />
                                    </S.ContentInput>
                                </S.Col>
                            </S.RowForm>

                            <S.RowForm>
                                <S.Col grid={2}>
                                    <S.ContentInput>
                                        <Label>Sexo:</Label>
                                        <S.Select onChange={e => setGender(e.target.value)}>
                                            <S.Option value={Number(0)} disabled selected>Selecione</S.Option>
                                            <S.Option value="M">Masculino</S.Option>
                                            <S.Option value="F">Feminino</S.Option>
                                        </S.Select>
                                    </S.ContentInput>
                                </S.Col>
                                <S.Col grid={5}>
                                    <S.ContentInput>
                                        <Label>E-mail:</Label>
                                        <S.InputText
                                            type="text"
                                            placeholder="Digite seu e-mail"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </S.ContentInput>
                                </S.Col>
                                <S.Col grid={5}>
                                    <S.ContentInput>
                                        <Label>Telefone:</Label>
                                        <S.InputTextMask
                                            mask="(99) 99999-9999"
                                            type="text"
                                            placeholder="Digite seu telefone"
                                            value={phone}
                                            onChange={e => setPhone(e.target.value)}
                                        />
                                    </S.ContentInput>
                                </S.Col>
                            </S.RowForm>

                            <S.RowForm>
                                <S.Col grid={3}>
                                    <S.ContentInput>
                                        <Label>CPF:</Label>
                                        <S.InputTextMask
                                            mask="999.999.999-99"
                                            type="text"
                                            placeholder="Digite seu CPF"
                                            value={cpf}
                                            onChange={e => setCpf(e.target.value)}
                                        />
                                    </S.ContentInput>
                                </S.Col>
                                <S.Col grid={3}>
                                    <S.ContentInput>
                                        <Label>Estado Civil:</Label>
                                        <S.Select onChange={e => setMaritalStatus(e.target.value)}>
                                            <S.Option value={Number(0)} disabled selected>Selecione</S.Option>
                                            <S.Option value="1">Solteiro</S.Option>
                                            <S.Option value="2">Casado</S.Option>
                                            <S.Option value="3">Divorciado</S.Option>
                                            <S.Option value="4">Viúvo</S.Option>
                                            <S.Option value="5">Concubinato</S.Option>
                                            <S.Option value="6">Separado</S.Option>
                                            <S.Option value="9">Outros</S.Option>
                                        </S.Select>
                                    </S.ContentInput>
                                </S.Col>
                                <S.Col grid={3}>
                                    <S.ContentInput>
                                        <Label>Número de filhos:</Label>
                                        <S.InputText
                                            type="text"
                                            placeholder="Ex: 2"
                                            value={numberOfChildren}
                                            onChange={e => setNumberOfChildren(e.target.value)}
                                            onBlur={handleSaveUser}
                                        />
                                    </S.ContentInput>
                                </S.Col>
                            </S.RowForm>
                        </S.Col>
                    </S.RowForm>
                </S.ContentForm>

                <S.ContentTitle>
                    <S.Title>Carteira de Identidade</S.Title>
                    <S.LineHorizontal />
                </S.ContentTitle>

                <S.ContentForm>
                    <S.RowForm>
                        <S.Col grid={3}>
                            <S.ContentInput>
                                <Label>Número:</Label>
                                <S.InputText
                                    type="text"
                                    placeholder="Digite o número"
                                    value={rgNumber}
                                    onChange={e => setRgNumber(e.target.value)}
                                />
                            </S.ContentInput>
                        </S.Col>
                        <S.Col grid={3}>
                            <S.ContentInput>
                                <Label>Orgão Emissor:</Label>
                                <S.InputText
                                    type="text"
                                    placeholder="Digite o orgão"
                                    value={rgEmittingOrgan}
                                    onChange={e => setRgEmittingOrgan(e.target.value)}
                                />
                            </S.ContentInput>
                        </S.Col>

                        <S.Col grid={3}>
                            <S.ContentInput>
                                <Label>Cidade:</Label>
                                <S.InputText
                                    type="text"
                                    placeholder="Digite sua cidade"
                                    value={rgCity}
                                    onChange={e => setRgCity(e.target.value)}
                                />
                            </S.ContentInput>
                        </S.Col>

                        <S.Col grid={3}>

                            <S.ContentInput>
                                <Label>Estado:</Label>
                                <S.Select onChange={e => setRgState(e.target.value)}>
                                    <S.Option value={Number(0)} disabled selected>Selecione</S.Option>
                                    <S.Option value="AC">AC</S.Option>
                                    <S.Option value="AL">AL</S.Option>
                                    <S.Option value="AP">AP</S.Option>
                                    <S.Option value="AM">AM</S.Option>
                                    <S.Option value="BA">BA</S.Option>
                                    <S.Option value="CE">CE</S.Option>
                                    <S.Option value="DF">DF</S.Option>
                                    <S.Option value="ES">ES</S.Option>
                                    <S.Option value="GO">GO</S.Option>
                                    <S.Option value="MA">MA</S.Option>
                                    <S.Option value="MT">MT</S.Option>
                                    <S.Option value="MS">MS</S.Option>
                                    <S.Option value="MG">MG</S.Option>
                                    <S.Option value="PA">PA</S.Option>
                                    <S.Option value="PB">PB</S.Option>
                                    <S.Option value="PR">PR</S.Option>
                                    <S.Option value="PE">PE</S.Option>
                                    <S.Option value="PI">PI</S.Option>
                                    <S.Option value="RJ">RJ</S.Option>
                                    <S.Option value="RN">RN</S.Option>
                                    <S.Option value="RS">RS</S.Option>
                                    <S.Option value="RO">RO</S.Option>
                                    <S.Option value="RR">RR</S.Option>
                                    <S.Option value="SC">SC</S.Option>
                                    <S.Option value="SP">SP</S.Option>
                                    <S.Option value="SE">SE</S.Option>
                                    <S.Option value="TO">TO</S.Option>
                                </S.Select>
                            </S.ContentInput>
                        </S.Col>
                    </S.RowForm>

                    <S.RowForm>
                        <S.Col grid={2}>
                            <S.ContentInput>
                                <Label>Nacionalidade:</Label>
                                <S.InputText
                                    type="text"
                                    placeholder="Digite sua nacionalidade"
                                    value={rgNationality}
                                    onChange={e => setRgNationality(e.target.value)}
                                />
                            </S.ContentInput>
                        </S.Col>

                        <S.Col grid={2}>
                            <S.ContentInput>
                                <Label>Data de nascimento:</Label>
                                <S.InputTextMask
                                    mask="99-99-9999"
                                    type="text"
                                    placeholder="Digite sua data de nascimento"
                                    value={birth}
                                    onChange={e => setBirth(e.target.value)}
                                />
                            </S.ContentInput>
                        </S.Col>

                        <S.Col grid={4}>
                            <S.ContentInput>
                                <Label>Nome do pai:</Label>
                                <S.InputText
                                    type="text"
                                    placeholder="Digite o nome do pai"
                                    value={fatherName}
                                    onChange={e => setFatherName(e.target.value)}
                                />
                            </S.ContentInput>
                        </S.Col>

                        <S.Col grid={4}>
                            <S.ContentInput>
                                <Label>Nome do mãe:</Label>
                                <S.InputText
                                    type="text"
                                    placeholder="Digite o nome da mãe"
                                    value={motherName}
                                    onChange={e => setMotherName(e.target.value)}
                                    onBlur={handleSaveUser}
                                />
                            </S.ContentInput>
                        </S.Col>
                    </S.RowForm>
                </S.ContentForm>

                <S.ContentTitle>
                    <S.Title>PIS/PASEP</S.Title>
                    <S.LineHorizontal />
                </S.ContentTitle>

                <S.ContentForm>
                    <S.RowForm>
                        <S.Col grid={2}>
                            <S.ContentInput>
                                <Label>Número:</Label>
                                <S.InputText
                                    type="text"
                                    placeholder="Digite o número"
                                    value={pisNumber}
                                    onChange={e => setPisNumber(e.target.value)}
                                />
                            </S.ContentInput>
                        </S.Col>
                        <S.Col grid={4}>
                            <S.ContentInput>
                                <Label>Candidato com deficiência?</Label>
                                <S.Select onChange={e => setDeficiency(e.target.value)}>
                                    <S.Option value={Number(0)} disabled selected>Selecione</S.Option>
                                    <S.Option value="SIM">Sim</S.Option>
                                    <S.Option value="NAO">Não</S.Option>
                                </S.Select>
                            </S.ContentInput>
                        </S.Col>
                        <S.Col grid={4}>
                            <S.ContentInput>
                                <Label>Conhece alguma pessoa que trabalha na empresa?</Label>
                                <S.Select onChange={e => setKnowSomeone(e.target.value)}>
                                    <S.Option value={Number(0)} disabled selected>Selecione</S.Option>
                                    <S.Option value="SIM">Sim</S.Option>
                                    <S.Option value="NAO">Não</S.Option>
                                </S.Select>
                            </S.ContentInput>
                        </S.Col>
                    </S.RowForm>
                    <S.RowForm>
                        <S.Col grid={12}>
                            <S.ContentInput>
                                <Label>Já participou de algum processo seletivo conosco ? Para qual vaga ? Quando?</Label>
                                <S.InputText
                                    type="text"
                                    placeholder="Informe"
                                    value={selectiveProcess}
                                    onChange={e => setSelectiveProcess(e.target.value)}
                                    onBlur={handleSaveUser}
                                />
                            </S.ContentInput>
                        </S.Col>
                    </S.RowForm>
                </S.ContentForm>

                <S.ContentTitle>
                    <S.Title>Endereço</S.Title>
                    <S.LineHorizontal />
                </S.ContentTitle>

                <S.ContentForm>
                    <S.RowForm>
                        <S.Col grid={2}>
                            <S.ContentInput>
                                <Label>Tipo:</Label>
                                <S.Select onChange={e => setTypeAddress(e.target.value)}>
                                    <S.Option value={Number(0)} disabled selected>Selecione</S.Option>
                                    <S.Option value="AV">Avenida</S.Option>
                                    <S.Option value="R">Rua</S.Option>
                                </S.Select>
                            </S.ContentInput>
                        </S.Col>
                        <S.Col grid={10}>
                            <S.ContentInput>
                                <Label>Endereço:</Label>
                                <S.InputText
                                    type="text"
                                    placeholder="Digite o endereço"
                                    value={address}
                                    onChange={e => setAddress(e.target.value)}
                                />
                            </S.ContentInput>
                        </S.Col>
                    </S.RowForm>
                    <S.RowForm>
                        <S.Col grid={8}>
                            <S.ContentInput>
                                <Label>Complemento:</Label>
                                <S.InputText
                                    type="text"
                                    placeholder="Digite o complemento"
                                    value={addressComplement}
                                    onChange={e => setAddressComplement(e.target.value)}
                                />
                            </S.ContentInput>
                        </S.Col>
                        <S.Col grid={2}>
                            <S.ContentInput>
                                <Label>Número:</Label>
                                <S.InputText
                                    type="text"
                                    placeholder="Digite o número"
                                    value={addressNumber}
                                    onChange={e => setAddressNumber(e.target.value)}
                                />
                            </S.ContentInput>
                        </S.Col>

                        <S.Col grid={2}>
                            <S.ContentInput>
                                <Label>Estado:</Label>
                                <S.Select onChange={e => setAddressState(e.target.value)}>
                                    <S.Option value={addressState} selected>{addressState !== '' ? addressState : 'Selecione'}</S.Option>
                                    <S.Option value="AC">AC</S.Option>
                                    <S.Option value="AL">AL</S.Option>
                                    <S.Option value="AP">AP</S.Option>
                                    <S.Option value="AM">AM</S.Option>
                                    <S.Option value="BA">BA</S.Option>
                                    <S.Option value="CE">CE</S.Option>
                                    <S.Option value="DF">DF</S.Option>
                                    <S.Option value="ES">ES</S.Option>
                                    <S.Option value="GO">GO</S.Option>
                                    <S.Option value="MA">MA</S.Option>
                                    <S.Option value="MT">MT</S.Option>
                                    <S.Option value="MS">MS</S.Option>
                                    <S.Option value="MG">MG</S.Option>
                                    <S.Option value="PA">PA</S.Option>
                                    <S.Option value="PB">PB</S.Option>
                                    <S.Option value="PR">PR</S.Option>
                                    <S.Option value="PE">PE</S.Option>
                                    <S.Option value="PI">PI</S.Option>
                                    <S.Option value="RJ">RJ</S.Option>
                                    <S.Option value="RN">RN</S.Option>
                                    <S.Option value="RS">RS</S.Option>
                                    <S.Option value="RO">RO</S.Option>
                                    <S.Option value="RR">RR</S.Option>
                                    <S.Option value="SC">SC</S.Option>
                                    <S.Option value="SP">SP</S.Option>
                                    <S.Option value="SE">SE</S.Option>
                                    <S.Option value="TO">TO</S.Option>
                                </S.Select>
                            </S.ContentInput>
                        </S.Col>
                    </S.RowForm>

                    <S.RowForm>
                        <S.Col grid={3}>
                            <S.ContentInput>
                                <Label>Cidade:</Label>
                                <S.Select onChange={e => setAddressCitySelected(e.target.value)}>
                                    <S.Option value={Number(0)} selected>{addressCity !== '' ? addressCity : 'Selecione'}</S.Option>
                                    {addressStateData.map(city => (
                                        <S.Option key={city.id} value={city.code}>{city.name}</S.Option>
                                    ))}
                                </S.Select>
                            </S.ContentInput>
                        </S.Col>
                        <S.Col grid={4}>

                            <S.ContentInput>
                                <Label>Bairro:</Label>
                                <S.Select onChange={e => setAddressNeightborhood(e.target.value)}>
                                    <S.Option value={Number(0)} selected>{addressNeighborhood !== '' ? addressNeighborhood : 'Selecione'}</S.Option>
                                    {addressNeightborhoodData.map(neightborhood => (
                                        <S.Option key={neightborhood.id} value={neightborhood.city_code}>{neightborhood.name}</S.Option>
                                    ))}
                                </S.Select>
                            </S.ContentInput>
                        </S.Col>

                        <S.Col grid={3}>
                            <S.ContentInput>
                                <Label>CEP:</Label>
                                <S.InputTextMask
                                    mask="99999-999"
                                    type="text"
                                    placeholder="Digite seu CEP"
                                    value={addressPostCode}
                                    onChange={e => setAddressPostCode(e.target.value)}
                                    onBlur={handleSaveUser}
                                />
                            </S.ContentInput>
                        </S.Col>
                    </S.RowForm>
                </S.ContentForm>

                <S.ContentTitle>
                    <S.Title>Área e Cargo pretendido</S.Title>
                    <S.LineHorizontal />
                </S.ContentTitle>

                <S.ContentForm>
                    <S.DescriptionForm>Queremos saber em quais áreas e/ou cargos você gostaria de trabalhar.</S.DescriptionForm>
                    <S.RowForm>
                        <S.Col grid={4}>
                            <S.ContentInput>
                                <Label>Cargo pretendido:</Label>
                                <S.Select onChange={e => setIntendedPosition(e.target.value)}>
                                    <S.Option value={Number(0)} selected>Selecione</S.Option>
                                    {positions.map(position => (
                                        <S.Option key={position.id} value={position.code}>{position.name}</S.Option>
                                    ))}
                                </S.Select>
                            </S.ContentInput>
                        </S.Col>
                        <S.Col grid={4}>
                            <S.ContentInput>
                                <Label>Área pretendida':</Label>
                                <S.InputText
                                    type="text"
                                    placeholder="Digite a área pretendida"
                                    value={intendedArea}
                                    onChange={e => setIntendedArea(e.target.value)}
                                />
                            </S.ContentInput>
                        </S.Col>
                        <S.Col grid={4}>
                            <S.ContentInput>
                                <Label>Pretensão salarial:</Label>
                                <S.InputTextMask
                                    mask="R$9.999,99"
                                    type="text"
                                    placeholder="Digite a pretensão salarial"
                                    value={wageClaim}
                                    onChange={e => setWageClaim(e.target.value)}
                                    onBlur={handleSaveUser}
                                />
                            </S.ContentInput>
                        </S.Col>
                    </S.RowForm>
                </S.ContentForm>

                <S.ContentButtons>
                    <S.ButtonSaveAndContinue
                        onClick={handleSaveUser}
                    >
                        <S.TextLinkSaveAndContinue>Salvar e continuar depois</S.TextLinkSaveAndContinue>
                    </S.ButtonSaveAndContinue>
                    <S.ButtonNextOrSave
                        loading={loading}
                        onClick={handleSaveAndNextPage}
                    >Próximo</S.ButtonNextOrSave>
                </S.ContentButtons>
            </S.Content>
        </S.Container>
    );
}

export default PersonalData;