import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import * as S from './styles';

import api from '../../services/api';

import { useToast } from '../../hooks/ToastContext';

import Header from '../../components/Header';
import Label from '../../components/Label';

interface IDataUsers {
	name: string;
	cpf: string;
	email: string;
	phone: string;
	id: number;
}

const RegisterAdmin: React.FC = () => {

	const [name, setName] = useState<string>();
	const [email, setEmail] = useState<string>();
	const [cpf, setCpf] = useState<string>();
	const [phone, setPhone] = useState<string>();
	const [password, setPassword] = useState<string>();
	const [dataUsers, setDataUsers] = useState<IDataUsers[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const { addToast } = useToast();

	useEffect(() => {
		api.get('v1/list-admin-users').then(response => {
			setDataUsers(response.data);
		});
	}, [loading]);

	function handleRegisterAdmin() {

		setLoading(true);

		api.post('v1/register', {
			name,
			password,
			id_permission: 1,
			email,
			phone,
			cpf
		}).then(response => {
			if (response.status === 201) {
				setLoading(false);
				setName('');
				setEmail('');
				setCpf('');
				setPhone('');
				setPassword('');

				addToast({
					type: 'success',
					title: 'Admin cadastrado com sucesso'
				});
			}
		})
	}

	function handleDeleteUser(id: number) {
		setLoading(true);
		api.delete(`v1/user/${id}`).then(response => {
			if (response.status === 204) {
				setLoading(false);

				addToast({
					type: 'success',
					title: 'Admin excluído com sucesso'
				});
			}
		});
	}

	return (
		<S.Container>
			<Header />
			<S.Content>
				<S.ContentFilters>
					<S.ContentTitle>
						<S.Title>Cadastro de administradores</S.Title>
						<S.LineHorizontal />
					</S.ContentTitle>
					<S.RowForm>

						<S.Col grid={4}>
							<S.ContentInput>
								<Label>Nome:</Label>
								<S.InputText
									type="text"
									placeholder="Digite o nome completo"
									value={name}
									onChange={e => setName(e.target.value)}
								/>
							</S.ContentInput>
						</S.Col>

						<S.Col grid={4}>
							<S.ContentInput>
								<Label>E-mail:</Label>
								<S.InputText
									type="text"
									placeholder="Digite o e-mail"
									value={email}
									onChange={e => setEmail(e.target.value)}
								/>
							</S.ContentInput>
						</S.Col>

						<S.Col grid={4}>
							<S.ContentInput>
								<Label>CPF:</Label>
								<S.InputTextMask
									mask="999.999.999-99"
									type="text"
									value={cpf}
									placeholder="Digite o cpf"
									onChange={e => setCpf(e.target.value)}
								/>
							</S.ContentInput>
						</S.Col>
					</S.RowForm>

					<S.RowForm>
						<S.Col grid={4}>
							<S.ContentInput>
								<Label>Telefone:</Label>
								<S.InputTextMask
									mask="(99) 99999-9999"
									type="text"
									placeholder="Digite o telefone"
									value={phone}
									onChange={e => setPhone(e.target.value)}
								/>
							</S.ContentInput>
						</S.Col>

						<S.Col grid={3}>
							<S.ContentInput>
								<Label>Senha:</Label>
								<S.InputText
									type="password"
									placeholder="Digite a senha"
									value={password}
									onChange={e => setPassword(e.target.value)}
								/>
							</S.ContentInput>
						</S.Col>

						<S.Col grid={3}>
							<S.ButtonRegister
								onClick={() => handleRegisterAdmin()}
								loading={loading}
							>Cadastrar</S.ButtonRegister>
						</S.Col>
					</S.RowForm>
				</S.ContentFilters>

				<S.RowForm>
					<S.Col grid={12}>
						<S.ContentTitle>
							<S.Title>Administradores cadastrados</S.Title>
							<S.LineHorizontal />
						</S.ContentTitle>
						<S.ContentTableHeader>
							<S.ColumnName>
								<h1>Nome</h1>
							</S.ColumnName>
							<S.ColumnCpf>
								<h1>CPF</h1>
							</S.ColumnCpf>
							<S.ColumnEmail>
								<h1>E-mail</h1>
							</S.ColumnEmail>
							<S.Phone>
								<h1>Telefone</h1>
							</S.Phone>
							<S.Action>
								<h1>Ação</h1>
							</S.Action>
						</S.ContentTableHeader>

						{dataUsers.map(user => (
							<S.ContentTable>
								<S.ColumnName>
									<h1>{user.name}</h1>
								</S.ColumnName>
								<S.ColumnCpf>
									<h1>{user.cpf}</h1>
								</S.ColumnCpf>
								<S.ColumnEmail>
									<h1>{user.email}</h1>
								</S.ColumnEmail>
								<S.Phone>
									<h1>{user.phone}</h1>
								</S.Phone>
								<S.Action>
									<Link to={`/edit-admin/${user.id}`}>
										<S.IconView />
									</Link>
									<S.IconTrash onClick={() => handleDeleteUser(user.id)} />
								</S.Action>
							</S.ContentTable>
						))}

					</S.Col>
				</S.RowForm>
			</S.Content>
		</S.Container>
	);
};

export default RegisterAdmin;


