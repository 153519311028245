import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import * as S from './styles';

import api from '../../services/api';

import Header from '../../components/Header';

import { useAuth } from '../../hooks/AuthContext';
import { useToast } from '../../hooks/ToastContext';

interface IOpportunities {
    id: string;
    cod: string;
    description: string;
    city: string;
    state: string;
    status: number;
}

const Opportunities: React.FC = () => {

    const [opportunities, setOpportunities] = useState<IOpportunities[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        api.get('v1/oportunity').then(response => {
            setOpportunities(response.data);
        });
    }, [loading]);

    const { user } = useAuth();
    const { addToast } = useToast();

    function handleRemoveOpportunity(id: string) {
        setLoading(true);

        api.delete(`v1/oportunity/${id}`).then(response => {
            if (response.status === 204) {

                setLoading(false);

                addToast({
                    type: 'success',
                    title: 'Vaga excluida com sucesso'
                });
            }
        })
    }

    return (
        <S.Container>
            <Header />
            <S.Content>

                <S.ContentTitle>
                    <S.Title>Oportunidades</S.Title>
                    <S.LineHorizontal />
                </S.ContentTitle>

                {user.id_permission === 1 && (
                    <S.ContentButtonRegister>
                        <Link to="/register-oportunity">
                            <S.ButtonRegisterOportunity>Cadastrar oportunidade</S.ButtonRegisterOportunity>
                        </Link>
                    </S.ContentButtonRegister>
                )}

                <S.ContentForm>
                    <S.RowForm>
                        {opportunities.map(opportunity => (
                            <S.ContentRow>
                                <S.ContentOpportunity>
                                    {user.id_permission === 1 && (
                                        <S.Status bg={opportunity.status === 1 ? '#04771e' : '#9c0404'}>{opportunity.status === 1 ? 'Ativa' : 'Inativa'}</S.Status>
                                    )}
                                    <S.TitleOpportunity>{opportunity.description}</S.TitleOpportunity>
                                    <S.DescriptionOpportunity>Código da vaga: {opportunity.id}</S.DescriptionOpportunity>
                                    <S.DescriptionOpportunity>{`Local: ${opportunity.city} - ${opportunity.state}`}</S.DescriptionOpportunity>
                                </S.ContentOpportunity>

                                <S.ContentDetails>
                                    <Link to={`/opportunity/${opportunity.id}`}>
                                        <S.ButtonNextOrSave>Detalhes</S.ButtonNextOrSave>
                                    </Link>
                                    {user.id_permission === 1 && (
                                        <>
                                            <Link to={`/edit-opportunity/${opportunity.id}`}>
                                                <S.ButtonNextOrSave>Editar</S.ButtonNextOrSave>
                                            </Link>
                                            <S.ButtonRemove
                                                onClick={() => handleRemoveOpportunity(opportunity.id)}
                                            >Excluir</S.ButtonRemove>
                                        </>
                                    )}
                                </S.ContentDetails>
                            </S.ContentRow>
                        ))}
                    </S.RowForm>
                </S.ContentForm>
            </S.Content>
        </S.Container>
    );
}

export default Opportunities;