import styled from 'styled-components';

import InputMask from 'react-input-mask';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { FiDownload, FiSearch } from 'react-icons/fi';

interface IPropsStyles {
    grid: number;
}

export const Container = styled.div`
    max-width: 80%;
    margin: 0 auto;
`;

export const Content = styled.div`
    // background: #EBEBEB;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 30px;
`;

export const ContentTitle = styled.div`
    margin: 25px 0;
`;

export const Title = styled.span`
    font-size: 15px;
`;

export const LineHorizontal = styled.div`
    background: #2398A3;
    height: 3px;
    margin: 3px 0;
`;

export const ContentData = styled.div`

`;

export const ContentTableHeader = styled.div`
    display: flex;
    flex-directions: row;
    justify-content: space-between;
    background: #d4d4d4;
    padding: 10px 5px;
    border-radius: 5px;
    margin-bottom: 5px;
    margin-top: 10px;
`;

export const ContentTable = styled.div`
    display: flex;
    flex-directions: row;
    justify-content: space-between;
    padding: 8px 5px;

    :hover {
        background: #eaeaea;
        border-radius: 5px;
    }
`;

export const ColumnName = styled.div`
    h1 {
        font-size: 15px;
    }

    width: 35%;
`;

export const ColumnCpf = styled.div`
    h1 {
        font-size: 16px;
    }

    width: 12%;
`;

export const ColumnEmail = styled.div`
    h1 {
        font-size: 16px;
    }

    width: 25%;
`;

export const Phone = styled.div`
    h1 {
        font-size: 16px;
    }

    width: 13%;
`;

export const Action = styled.div`
    h1 {
        font-size: 16px;
    }

    width: 5%;
    cursor: pointer;
`;

export const RowForm = styled.div`
    width: 100%;
    height: auto;
    float: left;
    display: table;
`;

export const Col = styled.div<IPropsStyles>`
        width: 100%;
        float: left;
        padding: 0 10px;

    @media only screen and (min-width: 768px) {
        width: ${props => (props.grid ? props.grid / 12 * 100 : '8:33')}%;
    }
`;

export const ContentFilters = styled.div`

`;

export const ContentInput = styled.div`
    width: 100%;
`;

export const InputTextMask = styled(InputMask)`
    margin-right: 10px;
    width: 100%;
    height: 45px;
    background: #C4C4C4;
    border-radius: 5px;
    padding: 20px;
    margin: 8px 0;
    font-size: 12px;

    ::placeholder,
    ::-webkit-input-placeholder {
    color: #676767;
    }
    :-ms-input-placeholder {
    color: #676767;
    }
`;

export const InputText = styled(Input)`
    margin-right: 10px;
    width: 100%;
`;

export const ButtonExport = styled(Button)`
    margin-top: 6px;
    width: 100px;
    margin-left: 10px;
    background: #255A21;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ContentButtons = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const IconDownload = styled(FiDownload)`
    font-size: 16px;
    margin-right: 10px;
`;

export const IconSearch = styled(FiSearch)`
    font-size: 16px;
    margin-right: 10px;
`;

export const ButtonRegister = styled(Button)`
    margin-top: 22px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const IconView = styled(FiSearch)`
    font-size: 22px;
`;