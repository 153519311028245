import styled from 'styled-components'

import Input from '../../components/Input';
import Button from '../../components/Button';

import InputMask from 'react-input-mask';

interface IPropsStyles {
    grid: number;
}

interface IPropsCard {
    bg: string;
}

export const Container = styled.div`
    max-width: 80%;
    margin: 0 auto;
`;

export const Content = styled.div`
    background: #EBEBEB;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 30px;
`;

export const ContentCandidates = styled.div`
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 30px;
`;

export const ContentHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Card = styled.div<IPropsCard>`
    background: ${props => props.bg};
    display: flex;
    flex-direction: column;
    width: 23%;
    height: 90px;
    border-radius: 5px;
`;

export const IconCard = styled.img`
    width: 60px;
    height: 53px;
`;

export const CardImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
`;

export const CardBottom = styled.div<IPropsCard>`
    background: ${props => props.bg};
    height: 25px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 5px 5px;
`;

export const TextCard = styled.span`
    color: #FFFFFF;
`;

export const ContentTitle = styled.div`
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Title = styled.span`
    font-size: 15px;
`;

export const LineHorizontal = styled.div`
    background: #2398A3;
    height: 3px;
    margin: 3px 0;
`;

export const ContentForm = styled.div`
    display: flex;
    flex-direction: column;
`;

export const RowForm = styled.div`
    width: 100%;
    height: auto;
    float: left;
    display: table;
`;

export const Col = styled.div<IPropsStyles>`
        width: 100%;
        float: left;
        padding: 0 10px;

    @media only screen and (min-width: 768px) {
        width: ${props => (props.grid ? props.grid / 12 * 100 : '8:33')}%;
    }
`;

export const ContentInput = styled.div`
    width: 100%;
`;

export const InputText = styled(Input)`
    margin-right: 10px;
    width: 100%;
`;

export const ContentPhoto = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Image = styled.img`
    width: 100%;
`;

export const ButtonUpLoadPhoto = styled(Button)`
    width: 120px;
`;

export const ContentButtons = styled.div`
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const TextLinkSaveAndContinue = styled.span`
    color: #0F3B83;
    font-size: 14px;
    font-weight: 600;
    margin-right: 20px;
`;

export const ButtonApply = styled(Button)`
    width: 100px;
    /* margin-top: -15px; */
    /* margin-right: 15px; */
`;

export const Select = styled.select`
    width: 100%;
    height: 45px;
    background: #C4C4C4;
    border-radius: 5px;
    padding: 10px;
    margin: 8px 0;
    font-size: 12px;
    border: 0;

    ::placeholder,
    ::-webkit-input-placeholder {
        color: #676767;
        padding: 10px;
    }
    :-ms-input-placeholder {
        color: #676767;
        padding: 10px;
    }
`;

export const Option = styled.option`
    
`;

export const InputTextMask = styled(InputMask)`
    margin-right: 10px;
    width: 100%;
    height: 45px;
    background: #C4C4C4;
    border-radius: 5px;
    padding: 20px;
    margin: 8px 0;
    font-size: 12px;

    ::placeholder,
    ::-webkit-input-placeholder {
    color: #676767;
    }
    :-ms-input-placeholder {
    color: #676767;
    }
`;

export const ContentProfessionalExperience = styled.div`
    
`;

export const RowColumns = styled.div`
    border-bottom: 1px solid #C4C4C4;
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    padding-bottom: 5px;
`;

export const Column = styled.div`
    
`;

export const TextColumn = styled.span`

`;

export const ButtonDelete = styled.button`
    border: none;
    background: #8c1515;
    width: 17px;
    height: 17px;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 15px;
    border-radius: 3px;
`;

export const ContentRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #E7E5E5;
    border-radius: 5px;
    padding: 5px;
    align-items: center;
    margin-top: 10px;
`;

export const ContentOpportunity = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TitleOpportunity = styled.span`
    font-size: 16px;
    font-weight: bold;
    /* margin-top: 10px; */
`;

export const DescriptionForm = styled.span`
    margin-bottom: 15px;
    margin-top: 10px;
    font-size: 14px;
    line-height: 22px;
`;

export const DescriptionOpportunity = styled.span`
    font-size: 15px;
    margin-top: 4px;
`;

export const ContentDetails = styled.div`

`;

export const RowContentOpportunity = styled.div`
    display: flex;
    flex-direction: column;
`;


export const ContentTableHeader = styled.div`
    display: flex;
    flex-directions: row;
    justify-content: space-between;
    background: #d4d4d4;
    padding: 10px 5px;
    border-radius: 5px;
    margin-bottom: 5px;
    margin-top: 10px;
`;

export const ContentTable = styled.div`
    display: flex;
    flex-directions: row;
    justify-content: space-between;
    padding: 8px 5px;

    :hover {
        background: #eaeaea;
        border-radius: 5px;
    }
`;

export const ColumnName = styled.div`
    h1 {
        font-size: 15px;
    }

    width: 35%;
`;

export const ColumnCpf = styled.div`
    h1 {
        font-size: 16px;
    }

    width: 12%;
`;

export const ColumnEmail = styled.div`
    h1 {
        font-size: 16px;
    }

    width: 25%;
`;

export const Phone = styled.div`
    h1 {
        font-size: 16px;
    }

    width: 13%;
`;

export const Action = styled.div`
    h1 {
        font-size: 16px;
    }

    width: 5%;
    cursor: pointer;
`;