import styled from 'styled-components'

import Input from '../../components/Input';
import Button from '../../components/Button';

import InputMask from 'react-input-mask';

interface IPropsStyles {
	grid: number;
}

interface IPropsCard {
	bg: string;
}

export const Container = styled.div`
    max-width: 80%;
    margin: 0 auto;
`;

export const Content = styled.div`
    background: #EBEBEB;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 30px;
`;

export const ContentHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Card = styled.div<IPropsCard>`
    background: ${props => props.bg};
    display: flex;
    flex-direction: column;
    width: 23%;
    height: 90px;
    border-radius: 5px;
`;

export const IconCard = styled.img`
    width: 60px;
    height: 53px;
`;

export const CardImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
`;

export const CardBottom = styled.div<IPropsCard>`
    background: ${props => props.bg};
    height: 25px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 5px 5px;
`;

export const TextCard = styled.span`
    color: #FFFFFF;
`;

export const ContentTitle = styled.div`
    margin: 25px 0;
`;

export const Title = styled.span`
    font-size: 15px;
`;

export const LineHorizontal = styled.div`
    background: #2398A3;
    height: 3px;
    margin: 3px 0;
`;

export const ContentForm = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const RowForm = styled.div`
    width: 100%;
    height: auto;
    float: left;
    display: table;
`;

export const Col = styled.div<IPropsStyles>`
        width: 100%;
        float: left;
        padding: 0 10px;

    @media only screen and (min-width: 768px) {
        width: ${props => (props.grid ? props.grid / 12 * 100 : '8:33')}%;
    }
`;

export const ContentInput = styled.div`
    width: 100%;
`;

export const InputText = styled(Input)`
    margin-right: 10px;
    width: 100%;
`;

export const ContentPhoto = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Image = styled.img`
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 7px;
    background: #C4C4C4;
`;

export const InputFile = styled.input`
    display: none;
`;

export const ButtonUpLoadPhoto = styled.label`
    width: 120px;
    background: #2B3B85;
    height: 45px;
    border-radius: 5px;
    border-width: 0;
    cursor: pointer;
    font-size: 14px;
    color: #FFF;
    font-weight: 600;
    border: none;
    transition: background-color 0.2s;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 10px;

    &:hover {
        background: #172877;
    }
`;

export const DescriptionForm = styled.span`
    margin-bottom: 15px;
    margin-left: 10px;
`;

export const ContentButtons = styled.div`
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const TextLinkSaveAndContinue = styled.span`
    color: #0F3B83;
    font-size: 14px;
    font-weight: 600;
    margin-right: 20px;
`;

export const ButtonNextOrSave = styled(Button)`
    width: 100px;
    margin-top: -15px;
    margin-right: 15px;
`;

export const Select = styled.select`
    width: 100%;
    height: 45px;
    background: #C4C4C4;
    border-radius: 5px;
    padding: 10px;
    margin: 8px 0;
    font-size: 12px;
    border: 0;

    ::placeholder,
    ::-webkit-input-placeholder {
        color: #676767;
        padding: 10px;
    }
    :-ms-input-placeholder {
        color: #676767;
        padding: 10px;
    }
`;

export const Option = styled.option`
    
`;

export const InputTextMask = styled(InputMask)`
    margin-right: 10px;
    width: 100%;
    height: 45px;
    background: #C4C4C4;
    border-radius: 5px;
    padding: 20px;
    margin: 8px 0;
    font-size: 12px;

    ::placeholder,
    ::-webkit-input-placeholder {
    color: #676767;
    }
    :-ms-input-placeholder {
    color: #676767;
    }
`;

export const ButtonSaveAndContinue = styled.button`
    border: none;
    background: transparent;
`;

export const TextArea = styled.textarea`
	margin-right: 10px;
    width: 100%;
    height: 150px;
    background: #C4C4C4;
    border-radius: 5px;
    padding: 20px;
    margin: 8px 0;
	font-size: 12px;
	border: none;

    ::placeholder,
    ::-webkit-input-placeholder {
    color: #676767;
    }
    :-ms-input-placeholder {
    color: #676767;
    }
`;
