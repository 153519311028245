import React from 'react';

import { BrowserRouter, Switch } from 'react-router-dom'

import { Route } from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import PersonalData from '../pages/PersonalData';
import ProfessionalTrajectory from '../pages/ProfessionalTrajectory';
import AcademicFormation from '../pages/AcademicFormation';
import Opportunities from '../pages/Opportunities';
import Opportunity from '../pages/Opportunity';
import Candidates from '../pages/Candidates';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import RegisterOportunity from '../pages/RegisterOportunity';
import RegisterAdmin from '../pages/RegisterAdmin';
import EditAdmin from '../pages/EditAdmin';
import EditOpportunity from '../pages/EditOpportunity';

const Routes: React.FC = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" component={SignIn} exact />
                <Route path="/signup" component={SignUp} />
                <Route path="/personal-data" component={PersonalData} isPrivate />
                <Route path="/professional-trajectory" component={ProfessionalTrajectory} isPrivate />
                <Route path="/academic-formation" component={AcademicFormation} isPrivate />
                <Route path="/opportunities" component={Opportunities} isPrivate />
                <Route path="/opportunity/:id" component={Opportunity} isPrivate />
                <Route path="/candidates" component={Candidates} isPrivate />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/reset-password/token/:token/email/:email" component={ResetPassword} />
                <Route path="/register-oportunity" component={RegisterOportunity} isPrivate />
                <Route path="/register-admin" component={RegisterAdmin} isPrivate />
                <Route path="/edit-admin/:id" component={EditAdmin} isPrivate />
                <Route path="/edit-opportunity/:id" component={EditOpportunity} isPrivate />
            </Switch>
        </BrowserRouter>
    );
}

export default Routes;