import React, { createContext, useCallback, useState, useContext } from 'react'
import api from '../services/api';

interface IUser {
    id: string;
    name: string;
    gender: string;
    email: string;
    phone: string;
    cpf: string;
    civil_state: string;
    number_of_children: string;
    id_permission: number;
}

interface IAuthState {
    token: string;
    user: IUser;
}

interface ISignInCredentials {
    email: string;
    password: string;
}

interface IAuthContextData {
    user: IUser;
    signIn(credentials: ISignInCredentials): Promise<void>;
    signOut(): void;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {

    const [data, setData] = useState<IAuthState>(() => {
        const token = localStorage.getItem('@Las:token');
        const user = localStorage.getItem('@Las:user');

        if (token && user) {
            api.defaults.headers.Authorization = `Bearer ${token}`;

            api.interceptors.response.use(
                (res) => res,

                async (err) => {
                    console.log("Interceptor response error");

                    console.log(err.response.status);

                    if (err.response.status === 401) {

                        localStorage.removeItem('@Las:token');
                        localStorage.removeItem('@Las:user');

                        setData({} as IAuthState);
                    }

                    return Promise.reject(err);
                },
            );

            return { token, user: JSON.parse(user) }
        }

        return {} as IAuthState;
    });

    const signIn = useCallback(async ({ email, password }) => {
        const response = await api.post('auth', {
            email,
            password
        });

        const { token, user } = response.data;

        localStorage.setItem('@Las:token', token);
        localStorage.setItem('@Las:user', JSON.stringify(user));

        api.defaults.headers.Authorization = `Bearer ${token}`;

        setData({ token, user });
    }, []);

    const signOut = useCallback(() => {
        localStorage.removeItem('@Las:token');
        localStorage.removeItem('@Las:user');

        setData({} as IAuthState);
    }, []);

    return (
        <AuthContext.Provider value={{ user: data.user, signIn, signOut }}>
            {children}
        </AuthContext.Provider>
    );
};

function useAuth(): IAuthContextData {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
}

export { AuthProvider, useAuth };