import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

interface ContainerProps {
    type?: 'success' | 'error' | 'info',
    hasDescription: boolean
}

const toastTypeVariations = {
    info: css`
        background: #2B3B85;
        color: #FFF;
    `,
    success: css`
        background: #12805C;
        color: #FFF;
    `,
    error: css`
        background: #970D13;
        color: #FFF;
    `,
};

export const Container = styled(animated.div)<ContainerProps>`
    width: 360px;
    position: relative;
    padding: 16px 30px 16px 16px;
    border-radius: 5px;
    z-index: 9999;
    display: flex; 

    & + div {
        margin-top: 8px;
    }

    ${props => toastTypeVariations[props.type || 'info']}

    > svg {
        margin: 4px 12px 0 0; 
    }

    div {
        flex: 1;
    }

    p {
        margin-top: 4px;
        font-size: 14px;
        opacity: 0.8;
        line-height: 20px;
    }

    button {
        position: absolute;
        right: 16px;
        top: 19px;
        opacity: 0.6;
        border: 0;
        background: transparent;
        color: inherit;
    }

    ${props => !props.hasDescription && css`
        align-items: center;

        svg {
            margin-top: 0;
        }
    `}
`;