import styled from 'styled-components';

import Input from '../../components/Input';
import Button from '../../components/Button';

import background from '../../assets/images/background-image.svg';

import { FiSend } from 'react-icons/fi';

export const Logo = styled.div`
    margin: 10px 0;
`;

export const Container = styled.div`
    background-image: url(${background});
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Content = styled.div`
    max-width: 400px;
    background: #FFFFFF;
    margin: 0 auto;
    border-radius: 5px;
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TextInput = styled(Input)`
    width: 340px;
`;

export const ButtonSign = styled(Button)`
    width: 340px;
`;

export const TextForgotPassword = styled.span`
    color: #0F3B83;
    font-weight: 600;
    margin-top: 10px;
`;

export const LineHorizontal = styled.div`
    width: 300px;
    background: #C4C4C4;
    height: 1px;
    margin: 10px 0;
`;

export const TextRegister = styled.span`
    margin-top: 10px; 
    color: #737380;
`;

export const TitleWelcome = styled.span`
    color: #0F3B83;
    font-weight: 600;
    font-size: 16px;
    margin: 10px 0;
`;

export const InfoEmail = styled.p`
    text-align: center;
    padding: 20px;
    color: #255A21;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
`;

export const IconSend = styled(FiSend)`
    font-size: 30px;
    margin-bottom: 10px;
`;