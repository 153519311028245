import styled from 'styled-components';

interface IPropsCard {
    bg: string;
}

export const ContentHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Card = styled.div<IPropsCard>`
    background: ${props => props.bg};
    display: flex;
    flex-direction: column;
    width: 23%;
    height: 90px;
    border-radius: 5px;
`;

export const IconCard = styled.img`
    width: 60px;
    height: 53px;
`;

export const CardImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
`;

export const CardBottom = styled.div<IPropsCard>`
    background: ${props => props.bg};
    height: 25px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 5px 5px;
`;

export const TextCard = styled.span`
    color: #FFFFFF;
`;